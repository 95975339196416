import React, { useState, useEffect } from 'react'

export const SkillNoDelete = ({ skillId = +new Date(), name = '', level = 0, css = {}, showDel = true, onDelete = (sid) => { console.log('SkillId', sid) } }) => {





    const [randomNumber, setRandomNumber] = useState(null)

    // Генерация случайного числа от 1 до 5
    const generateRandomNumber = () => {
        return Math.floor(Math.random() * 2) + 1
    }

    // Установка случайного числа при монтировании компонента
    useEffect(() => {
        setRandomNumber(generateRandomNumber())
    }, [])


    return (
        <div style={{ display: 'inline-block', margin: '5px' }}>
            <div className='d-flex justify-start align-center' style={{ maxWidth: '300px' }}>

                <div style={{ color: '#4bb0b0', border: '1px solid #4bb0b0', borderRadius: '5px', height: '25px', maxWidth: '250px', ...css }} className='d-flex justify-between align-center onHover'>
                    <span style={{
                        color: '#4bb0b0',
                        textAlign: 'center',
                        width: '100%',
                        textOverflow: 'ellipsis',
                        wordWrap: 'normal',
                        whiteSpace: 'nowrap',
                        overflowX: 'hidden',
                        padding: '0 5px'
                    }}>
                        {name}
                    </span>
                    <span style={{
                        height: '100%',
                        backgroundColor: '#4bb0b0',
                        color: '#fff',
                        textAlign: 'center',
                        borderRadius: '3px',
                        width: '34px'
                    }}>
                        {level}
                    </span>
                </div>

                {/* <div
                    style={{ border: '1px solid #ccc', borderRadius: '5px', height: '25px', width: '25px', color: '#ccc', textAlign: 'center', marginLeft: '5px' }}
                >
                    <span>+{}</span>

                </div> */}


            </div>
        </div>
    )
}