import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom"
import { ArchPage } from "./pages/Architecture/ArchPage"
import { BlockPage } from "./pages/Architecture/BlockPage"
import { AuthPage } from './pages/AuthPage'
import { DashboardPage } from "./pages/Dashboard/DashboardPage"
import { MainPage } from "./pages/MainPage"
import { MembersPage } from "./pages/members/MembersPage"
import { ProfilePage } from "./pages/ProfilePage"
import { ProjectCreatePage } from "./pages/projects/ProjectCreatePage"
import { ProjectGeneralPage } from "./pages/projects/ProjectGeneralPage"
import { ProjectJiraPage } from "./pages/projects/ProjectJiraPage"
import { RoadmapPage } from "./pages/Roadmap/RoadmapPage"
import { SearchPage } from "./pages/SearchPage"
import { SkillPage } from "./pages/Skill/SkillPage"
import { TeamSkillPage } from "./pages/Skill/TeamSkillPage"

function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/auth', state: { from: props.location } }} />}
        />
    )
}

export const useRoutes = isAuth => {
    return (
        <Switch>
            <PrivateRoute authed={isAuth} path="/" component={() => (<MainPage />)} exact />
            <PrivateRoute authed={isAuth} path="/user/profile" component={() => (<ProfilePage page='Profile' />)} exact />
            <PrivateRoute authed={isAuth} path="/user/password" component={() => (<ProfilePage page='Password' />)} exact />
            <PrivateRoute authed={isAuth} path="/user/notify" component={() => (<ProfilePage page='Notification' />)} exact />
            <PrivateRoute authed={isAuth} path='/members/' component={MembersPage} exact />

            <PrivateRoute authed={isAuth} path='/search/:str' component={SearchPage} />

            <PrivateRoute authed={isAuth} path='/project/create' component={ProjectCreatePage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/general" component={ProjectGeneralPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/integrations" component={ProjectJiraPage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/blocks" component={BlockPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/arch" component={ArchPage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/skill" component={SkillPage} exact />
            <PrivateRoute authed={isAuth} path="/project/:id/teamskill" component={TeamSkillPage} exact />


            <PrivateRoute authed={isAuth} path="/allSkills" component={SkillPage} exact />
            <PrivateRoute authed={isAuth} path="/allStaffSkills" component={TeamSkillPage} exact />



            <PrivateRoute authed={isAuth} path="/project/:id/roadmap" component={RoadmapPage} exact />

            <PrivateRoute authed={isAuth} path="/project/:id/dashboard" component={DashboardPage} exact />

            <Route path="/auth" exact>
                {isAuth ? <MainPage /> : <AuthPage />}
            </Route>

            <Redirect to="/" />
        </Switch>
    )
}