import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
// import {SidebarContext} from "../../context/SidebarContext"
import { Loader } from "../partials/Loader"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"

export const ProjectJiraPage = () => {
    const { token, logout } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    const id = useParams().id
    // const sBC = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [form, setForm] = useState({
        jiraUrl: '',
        jiraName: '',
        jiraPass: '',
        jiraProject: '',
        jiraHRProject: ''
    })
    const [projects, setProjects] = useState([])
    const [connected, setConnected] = useState(false)

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        window.M.FormSelect.init(document.querySelectorAll('select'))
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setProject(data.project)
                setForm({
                    jiraUrl: data.project.jiraUrl || '',
                    jiraName: data.project.jiraName || '',
                    jiraPass: data.project.jiraPass || '',
                    jiraProject: data.project.jiraProject || '',
                    jiraHRProject: data.project.jiraHRProject || ''
                })

            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const getjiraProjects = useCallback(async (token, form, hr = null) => {
        try {
            const data = await request(`/api/jira/allprojects`, 'POST', { ...form, hr }, { authorization: 'Bearer ' + token })

            if (data.connected) {
                setProjects(data.projects)
                if (!form.jiraHRProject) {
                    setForm(prev => { return { ...prev, jiraHRProject: data.projects[0].key } })
                }

                if (!form.jiraProject) {
                    setForm(prev => { return { ...prev, jiraProject: data.projects[0].key } })
                }

                setConnected(true)
            }

            message(data.message)

        } catch (e) {
            console.log(e)
        }
    }, [request])

    useEffect(() => {
        // prj.toggle(true, id)
        getProject(token, id)

    }, [getProject, id, token])

    // useEffect(() => {
    //     console.log('form', form)
    //     console.log('projects', projects)
    // }, [form, projects])

    const changeHandler = event => {
        setForm(prev => { return { ...prev, [event.target.name]: event.target.value } })
    }

    const updateHandler = async () => {
        try {
            const data = await request(`/api/project/${id}`, 'PUT', { form }, { authorization: 'Bearer ' + token })
            message(data.message)
        } catch (e) { }
    }

    const connectHandler = async () => {
        try {
            await getjiraProjects(token, form)
        } catch (e) {
            console.log(e)
        }
    }

    if (!project || loading) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Интеграция с Jira - {project.name}</h5>
                    <p className="txt-gray">Подключить проект к Jira</p>
                </div>
            </div>

            <div className="row clear-row flex-row">
                <div className="col s6 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            type="text"
                            id="url"
                            className=""
                            name="jiraUrl"
                            value={form.jiraUrl}
                            onChange={changeHandler}
                        />
                        <label htmlFor="url">URL Сервера </label>
                    </div>

                    <div className="input-field">
                        <input
                            type="text"
                            id="username"
                            className=""
                            name="jiraName"
                            value={form.jiraName}
                            onChange={changeHandler}
                        />
                        <label htmlFor="username">Имя пользователя</label>
                    </div>

                    <div className="input-field">
                        <input
                            type="password"
                            id="password"
                            className=""
                            name="jiraPass"
                            value={form.jiraPass}
                            onChange={changeHandler}
                        />
                        <label htmlFor="password">Пароль</label>
                    </div>

                    {/*Save changes*/}

                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <button
                            className="waves-effect waves-light btn blue darken-1 noUpper mr-1"
                            onClick={updateHandler}
                            disabled={loading || !connected}
                        >
                            Подключение
                        </button>

                        {/*Test connection*/}
                        <button
                            style={{ border: '1px solid grey', color: 'black' }}
                            className="waves-effect waves-dark btn white lighten-1 noUpper"
                            onClick={connectHandler}
                            disabled={loading}
                        >
                            Тестовое подключение
                        </button>
                    </div>

                </div>
            </div>

            <div className="row clear-row mt-3" style={!connected ? { display: 'none' } : {}}>
                <div className="col-auto">
                    <p style={{ fontSize: '1.3rem' }}>Настройки проекта</p>
                </div>
            </div>

            <div className="row clear-row flex-row" style={!connected ? { display: 'none' } : {}}>
                <div className="col s6 ml-0 pl-0">
                    {/*Jira Project*/}
                    <div className="input-field">
                        <select
                            id="jiraProject"
                            name="jiraProject"
                            value={form.jiraProject}
                            onChange={changeHandler}
                        >
                            {projects.length ? projects.map(p => <option key={p.id} value={p.key}>{p.name}</option>) : (<option value="0">-</option>)}
                        </select>
                        <label htmlFor="jiraProject">Выберите проект</label>
                    </div>

                    {/*Jira HR Project*/}
                    <div className="input-field">
                        <select
                            id="jiraHRProject"
                            name="jiraHRProject"
                            value={form.jiraHRProject}
                            onChange={changeHandler}
                        >
                            {projects.length ? projects.map(p => <option key={p.id} value={p.key}>{p.name}</option>) : (<option value="0">-</option>)}
                        </select>
                        <label htmlFor="jiraHRProject">Выберите HR проект</label>
                    </div>
                </div>
            </div>
        </>
    )

}