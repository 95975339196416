import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom"
// import {SidebarContext} from "../../context/SidebarContext"
import { Loader } from "../partials/Loader"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"

export const ProjectGeneralPage = () => {
    const { token, user, logout, login } = useContext(AuthContext)
    const { loading, request, error, clearError } = useHttp()
    const id = useParams().id
    // const prj = useContext(SidebarContext)
    const [project, setProject] = useState(null)
    const message = useMessage()
    const history = useHistory()
    const [form, setForm] = useState({
        name: '',
        description: '',
        slug: ''
    })

    useEffect(() => {
        message(error)
        if (error === 'Нет авторизации') {
            clearError()
            logout()
            history.push('/')
        }
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setProject(data.project)
                setForm({ name: data.project.name, description: data.project.description, slug: data.project.slug })
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    useEffect(() => {
        // prj.toggle(true, id)
        getProject(token, id)

    }, [getProject, id, token])

    const changeHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const updateHandler = async () => {
        try {
            const data = await request(`/api/project/${id}`, 'PUT', { ...form }, { authorization: 'Bearer ' + token })
            setForm({ name: data.project.name, description: data.project.description, slug: data.project.slug })
            message(data.message)
        } catch (e) { }
    }

    const deleteHandler = async () => {
        try {
            const data = await request(`/api/project/${id}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)

            // update user in context
            // get user
            const usr = await request('/api/auth/getWithProjetcs', 'POST', { email: user.email }, { authorization: 'Bearer ' + token })
            login(token, user.id, usr.user)

            // prj.toggle(false)
            history.push('/')

        } catch (e) { }
    }

    if (!project || loading) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row mt-noHeader">
                <div className="col-auto">
                    <h5>Проект - {project.name}</h5>
                    <p className="txt-gray">Управление настройками проекта</p>
                </div>
            </div>



            <div className="row clear-row flex-row">
                <div className="col s4 mt-2 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            placeholder="Project name"
                            type="text"
                            id="name"
                            className=""
                            name="name"
                            value={form.name}
                            autoComplete="off"
                            onChange={changeHandler}
                        />
                        <label htmlFor="name">Название проекта</label>
                    </div>




                    <div className="input-field">
                        <textarea
                            id="description"
                            name="description"
                            value={form.description}
                            onChange={changeHandler}
                            className="materialize-textarea" />

                        <label htmlFor="name">Описание проекта</label>
                    </div>






                    <button
                        className="waves-effect waves-light btn blue darken-1 noUpper"
                        onClick={updateHandler}
                        disabled={loading}
                    >
                        Сохранить настройки
                    </button>
                </div>
                <div className="col s4 mt-2 ml-0 pl-0">
                    <div className="input-field">
                        <input
                            placeholder="Идентификатор проекта"
                            type="text"
                            id="slug"
                            className=""
                            name="slug"
                            value={form.slug}
                            autoComplete="off"
                            onChange={changeHandler}
                            disabled
                            style={{ borderBottom: '0px' }}
                        />
                        <label htmlFor="slug">Идентификатор проекта</label>
                    </div>
                </div>

            </div>

            {/*<div className="row clear-row">*/}
            {/*<div className="col-auto ml-0">*/}
            {/*<button*/}
            {/*className="waves-effect waves-light btn blue darken-1 noUpper"*/}
            {/*onClick={updateHandler}*/}
            {/*disabled={loading}*/}
            {/*>*/}
            {/*Save settings*/}
            {/*</button>*/}
            {/*</div>*/}
            {/*</div>*/}

            {/*<br/>*/}
            <div className="row clear-row">
                <div className="col-auto w-100" style={{ paddingTop: '5px' }}>
                    <hr style={{ display: 'block', height: '1px', border: '0', borderTop: '1px solid #eee' }} />
                </div>
            </div>
            {/*<br/>*/}

            <div className="row clear-row">
                <div className="col-auto">
                    <p style={{ fontSize: '1.2rem' }}>Удалить проект</p>
                    <p className="txt-gray">Удаление проекта также навсегда удалит любые связанные с ним данные. <br /> Действие нельзя отменить, ваши данные не могут быть восстановлены!</p>
                </div>
            </div>

            <div className="row clear-row flex-row align-center">
                <div className="col-auto ml-0">
                    <button
                        className="waves-effect waves-light btn red lighten-1 noUpper"
                        onClick={deleteHandler}
                        disabled={loading}
                    >
                        Удалить проект
                    </button>
                </div>
            </div>
        </>
    )

}