import React, { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { AuthContext } from "../../context/AuthContext"
import { Link, useHistory, useParams } from "react-router-dom"
import { useMessage } from "../../hooks/message.hook"
import { useHttp } from "../../hooks/http.hook"
import { Loader } from "../partials/Loader"
import { Header } from "../partials/Header"
import { Icon } from "../partials/Icon"
import ReactFlow, {
    Background, Controls, addEdge, ReactFlowProvider, removeElements,
    ControlButton
} from 'react-flow-renderer'
import { Handle } from 'react-flow-renderer'
import { FlowSidebar } from "./FlowSidebar"
import { FlowProps } from "./FlowProps"


export const ArchPage = () => {
    const id = useParams().id
    const { token, logout } = useContext(AuthContext)
    const history = useHistory()
    const { loading, request, silentRequest, error, clearError } = useHttp()
    const message = useMessage()
    const [project, setProject] = useState(null)

    const reactFlowWrapper = useRef(null)
    const [reactFlowInstance, setReactFlowInstance] = useState(null)

    // const edgeType = 'smoothstep'
    const edgeType = 'default'
    const snapGrid = [12, 12]

    console.log("project ------- ", project)

    // const initialEls = [
    //     {
    //         id: '1',
    //         type: 'input', // input node
    //         data: { label: 'Input Node' },
    //         position: { x: 250, y: 25 },
    //     },
    //     // default node
    //     {
    //         id: '2',
    //         // you can also pass a React component as a label
    //         data: { label: <div>Default Node</div> },
    //         position: { x: 100, y: 125 },
    //     },
    //     {
    //         id: '3',
    //         type: 'output', // output node
    //         data: { label: 'Output Node' },
    //         position: { x: 250, y: 250 },
    //     },
    //     {
    //         id: '4',
    //         type: 'special', // input node
    //         data: { label: 'Input Node' },
    //         b: null,
    //         position: { x: 250, y: 300 },
    //     },
    //     // animated edge
    //     { id: 'e1-2', source: '1', target: '2', animated: true, type: edgeType },
    //     { id: 'e2-3', source: '2', target: '3', type: edgeType },
    // ]

    const [elements, setElements] = useState([])
    const [showProps, setShowProps] = useState(false)
    const [currBlock, setCurrBlock] = useState(null)
    const [currNode, setCurrNode] = useState(null)



    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
        if (document.getElementById('description')) window.M.textareaAutoResize(document.getElementById('description'))
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/blocks/${id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setProject(data.project)

                console.log("{}POPOIUGUHVUY------", data.project)
                // before setElements do renew it (maybe some blocks changed or deleted ?)
                if (data.project && data.project.blocks.length && data.project.archStr && !elements.length) {
                    let loadedEls = JSON.parse(data.project.archStr)
                    // setElements(loadedEls)
                    // compare each node element with real project.blocks[i]
                    console.log('loadedEls before', loadedEls)
                    loadedEls = loadedEls.map(el => {
                        if (el.id[0] === 'd') {
                            let pBlock = data.project.blocks.find(b => b.id === el.data.b.id)
                            if (pBlock) {
                                el.data.b = { ...pBlock }
                            } else {
                                if (!el.data.b.name.includes('DELETED!')) el.data.b.name = `DELETED! ${el.data.b.name}`
                            }
                        }
                        return el
                    })
                    console.log('loadedEls after', loadedEls)
                    setElements(loadedEls)
                }
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    useEffect(() => {
        // prj.toggle(true, id)
        getProject(token, id)

    }, [getProject, id, token])

    // logging
    // useEffect(() => {
    //     console.log('project', project)
    //     console.log('elements', elements)
    //     if (reactFlowInstance) console.log('InsEls', reactFlowInstance.toObject().elements)
    // }, [project, elements, reactFlowInstance])

    // Flow start
    const saveFlow = useCallback(async (token, id, flow) => {
        try {
            const data = await silentRequest(`/api/project/archUpd`, 'POST', { id, archStr: JSON.stringify(flow) }, { authorization: 'Bearer ' + token })
            // const data = await request(`/api/project/archUpd`, 'POST', {id, archStr: JSON.stringify(elements)}, {authorization: 'Bearer ' + token})
            // setProject(data.project)
            // console.log('SAVING ARCH')
        } catch (e) {
            console.log(e)
        }
    }, [silentRequest])

    useEffect(() => {
        if (token && id && reactFlowInstance) {
            saveFlow(token, id, reactFlowInstance.toObject().elements)
        }
    }, [elements, id, token])

    const onConnect = useCallback(
        (params) =>
            setElements((els) =>
                addEdge({ ...params, type: edgeType }, els)
                // addEdge({ ...params, type: edgeType, style: { stroke: '#f00' } }, els)
                // addEdge({ ...params, animated: true, style: { stroke: '#f00' } }, els)
            ),
        []
    )

    const blockDownload = useCallback(async (token, blockIds, block) => {

        console.log("block, ", block)
        try {
            if (token) {
                // on server create zip in temp folder
                const data = await request(`/api/block/zip`, 'POST', { blockIds, block }, { authorization: 'Bearer ' + token })
                message(data.message)

                // const z = await request(`/api/block/zipdl/${data.dir}`, 'GET', null, {authorization: 'Bearer ' + token})
                // const z = await fetch(`/api/block/zipdl/${data.dir}`, {method: 'GET', body: null, headers: {authorization: 'Bearer ' + token}})

                // console.log(data.path)

                let link = document.createElement('a')

                link.href = window.location.protocol + '//' + window.location.host + `/${data.path}`
                // link.href = window.location.protocol+'//'+ window.location.host+`/assets/zips//${data.fName}`
                console.log(link.href)
                link.setAttribute('download', data.fName)
                document.body.appendChild(link)
                link.click()
                link.parentNode.removeChild(link)

                // const z = await request(`/api/block/zipdl/${data.dir}`, 'POST', null, {authorization: 'Bearer ' + token})

            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const onElementsRemove = (elementsToRemove) =>
        setElements((els) => removeElements(elementsToRemove, els))

    const onLoad = (_reactFlowInstance) =>
        setReactFlowInstance(_reactFlowInstance)

    const onDragOver = (event) => {
        event.preventDefault()
        event.dataTransfer.dropEffect = 'move'
    }

    const onDragStop = (event) => {
        event.preventDefault()
        // reSet Elements, it's force save Flow to DB
        setElements(els => [...els])
    }

    const onElementClick = (event, node) => {
        console.log("123", node)
        event.preventDefault()
        // console.log('node', node.data.b)
        setCurrBlock(project.blocks.find(b => b.id === node.data.b.id))
        setCurrNode(node)
        setShowProps(true)
    }


    console.log("currnt node --=-= --=-=-=-=-=", currBlock)
    // FlowProps callbacks Start
    const onCloseProps = () => {
        setShowProps(false)
    }

    const updateBlock = (blockId, propId, selOptId) => {
        // change property.selectedId in ArchPage.project.blocks & elements & instance.elements?
        // console.log(`updBlock: blockId: ${blockId}, propId: ${propId}, selOptId: ${selOptId}`)

        // for work used only project.blocks!
        let idx = project.blocks.findIndex(b => b.id === blockId)
        if (idx >= 0) {
            let pIdx = project.blocks[idx].properties.findIndex(p => p.id === propId)
            if (pIdx >= 0) {
                // console.log('prev sel opt', project.blocks[idx].properties[pIdx].selectedId)
                // console.log('new sel opt', selOptId)
                project.blocks[idx].properties[pIdx].selectedId = selOptId
                setProject(project)
            }
        }
    }

    const onDeleteAll = (nId) => {
        console.log('block id', nId)
        // console.log('els', elements)
        // setElements([])
        if (nId) setElements(prev => prev.filter(el => el.id !== nId).filter(el => el.target !== nId).filter(el => el.source !== nId))
        setShowProps(false)
    }
    // FlowProps callbacks End

    const onDrop = (event) => {
        event.preventDefault()

        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect()
        const type = event.dataTransfer.getData('application/reactflow')
        const b = JSON.parse(event.dataTransfer.getData('b')) // my data from sidebar component
        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        })
        const newNode = {
            id: `dndnode_${+ new Date()}`,
            type,
            position,
            data: { label: `${type} node`, b },
        }

        setElements((es) => es.concat(newNode))

    }

    const CustomNode = ({ data, id }) => {
        let b = data.b
        console.log('Data in component', data)
        return (
            <>
                <Handle
                    type="target"
                    position="top"
                />

                <div className="d-flex justify-start" style={{ borderRadius: '5px', boxShadow: '2px 2px 2px #e2e2e2', padding: '10px', backgroundColor: '#fff', border: '1px solid #e2e2e2', maxWidth: '250px', minWidth: '250px' }}>
                    <div className="">
                        {b.type === 'Action' ? <Icon name='flash' size='20px' mt='6px' mr='0' /> : <Icon name='box' size='20px' mt='6px' mr='0' />}
                    </div>
                    <div className="d-flex flex-column w-100">
                        <div className="blockin d-flex flex-column">
                            <div className="blocktext">
                                <p className="blocktitle" style={{ color: b.name.includes('DELETED!') ? 'red' : 'black' }}>{b.name}</p>
                                <p className="blockdesc">{b.description}</p>
                            </div>
                            <div className="d-flex justify-end" style={{ marginTop: '-15px' }}>
                                <i
                                    style={{ cursor: 'pointer' }}
                                    className="fa fa-trash-o txt-gray clear-minWidth flow-icon"
                                    aria-hidden="true"
                                    onClick={
                                        () => {
                                            // console.log('clicked delete:', `Node id ${id}, Block id: ${b.id}`)
                                            // delete Node and linked edges
                                            setElements(prev => prev.filter(el => el.id !== id).filter(el => el.target !== id).filter(el => el.source !== id))
                                            setShowProps(false)
                                        }
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <Handle
                    type="source"
                    position="bottom"
                />
            </>
        )
    }
    // Flow end

    if (!project || loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Модули`,
                subTitle: 'Управление модулями',
                bk: [
                    {
                        title: project ? project.name : '',
                        // actionHandler: () => {history.goBack()}
                        actionHandler: () => { }
                    },
                    {
                        title: `Архитектура`,
                        actionHandler: () => { }
                    },
                ],
                btnL: {
                    actionHandler: () => { console.log('left') },
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: () => { },
                    title: 'Создать модуль',
                    display: 'none'
                },
                loading
            }} />

            <div className="container-fluid" style={{ position: 'fixed', left: '280px', top: '65px', bottom: '0', right: '0' }}>
                <div className="row clear-row my-0 py-0" style={{ height: '100%' }}>
                    <div className="col s12" style={{ height: '100%' }}>
                        <div className="dndflow">
                            <ReactFlowProvider>
                                <FlowSidebar project={project} getProject={getProject} />
                                <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                                    <ReactFlow
                                        elements={elements}
                                        nodeTypes={{ special: CustomNode }}
                                        onConnect={onConnect}
                                        onElementsRemove={onElementsRemove}
                                        snapToGrid={true}
                                        snapGrid={snapGrid}
                                        onElementClick={onElementClick}
                                        onLoad={onLoad}
                                        onDrop={onDrop}
                                        onNodeDragStop={onDragStop}
                                        onDragOver={onDragOver}
                                    >
                                        <Background
                                            variant="dots"
                                            gap={12}
                                            size={0.5}
                                        />
                                        <Controls showZoom={false} showInteractive={false}>
                                            {/* <ControlButton onClick={() => {
                                                console.log('action Download all')
                                                if (elements.length) {
                                                    let blockIds = elements.filter(el => el.id[0] === 'd').map(el => el.data.b.id)

                                                    blockIds = [...new Set(blockIds)]
                                                    blockDownload(token, blockIds)
                                                }
                                            }}>
                                                <i
                                                    style={{ cursor: 'pointer' }}
                                                    className="fa fa-download txt-gray clear-minWidth"
                                                    aria-hidden="true"
                                                />
                                            </ControlButton> */}
                                        </Controls>
                                    </ReactFlow>
                                </div>
                                {/* {showProps ? <FlowProps block={currBlock} node={currNode} onCloseProps={onCloseProps} onDeleteAll={onDeleteAll} updateBlock={updateBlock} /> : <></>} */}
                            </ReactFlowProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}