import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AuthContext } from "../../context/AuthContext"
import { Loader } from "./Loader"
import { useHttp } from "../../hooks/http.hook"
import { SidebarContext } from "../../context/SidebarContext"

export const Sidebar = () => {
    const history = useHistory()
    const proj = useContext(SidebarContext)
    const auth = useContext(AuthContext)
    const { loading, request } = useHttp()
    const [projects, setProjects] = useState(null)
    const [selected, setSelected] = useState(null)

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        // Инициализируем Dropdown
        // let el = document.querySelector('.dropdown-trigger')
        // window.M.Dropdown.init(el)
    }, [])

    useEffect(() => {
        // console.log('proj', proj)
    }, [proj])

    const getProject = useCallback(async (token) => {
        try {
            if (token) {
                const u = await request(`/api/auth/check`, 'POST', { userId: auth.user.id }, { authorization: 'Bearer ' + token })
                if (!u.isUser) {
                    auth.logout()
                    history.push('/')
                    return
                }

                const data = await request(`/api/project/projects`, 'POST', null, { authorization: 'Bearer ' + token })
                setProjects(data.projects)
                auth.login(token, auth.user.id, { ...auth.user, projects: data.projects })
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    // load project + label + users
    useEffect(() => {
        try {
            getProject(auth.token)
        } catch (e) {
            console.log(e)
        }
    }, [getProject])

    useEffect(() => {
        // if (selected && !loading) {
        if (!loading) {
            let els = document.querySelectorAll('li')
            els.forEach(el => el.classList.remove('active-menu'))

            if (proj.id && selected === 'add') {
                document.getElementById(`prj-${proj.id}-int`).classList.add('active-menu')
                return
            }

            if (proj.id && proj.menuId === 'block') {
                document.getElementById(`prj-${proj.id}-blk`).classList.add('active-menu')
                return
            }

            if (proj.id && proj.menuId === 'integration') {
                document.getElementById(`prj-${proj.id}-int`).classList.add('active-menu')
                return
            }

            if (proj.id && proj.menuId === 'dashboard') {
                document.getElementById(`prj-${proj.id}-dash`).classList.add('active-menu')
                return
            }

            if (proj.id && proj.menuId === 'arch') {
                document.getElementById(`prj-${proj.id}-arch`).classList.add('active-menu')
                return
            }

            // document.getElementById(selected) ? document.getElementById(selected).classList.add('active-menu') : document.getElementById('cases').classList.add('active-menu')
            document.getElementById(selected) && document.getElementById(selected).classList.add('active-menu')
        }

    }, [getProject, selected, proj])

    const logoutHandler = (e) => {
        e.preventDefault()
        auth.logout()
        history.push('/')
    }

    const projectHandler = (e, prjId) => {
        if (e.target.querySelector('.fa.fa-chevron-down')) {
            e.target.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        } else {
            e.target.parentElement.querySelector('.fa.fa-chevron-down').classList.toggle('d-none')
            e.target.parentElement.querySelector('.fa.fa-chevron-right').classList.toggle('d-none')
        }
        let els = document.querySelectorAll(`.prj-${prjId}`)
        if (els) {
            els.forEach(el => {
                el.classList.toggle('d-none')
            })
        }
    }

    const menuClickHandler = async (e, path) => {
        proj.toggleMenu(null)

        await getProject(auth.token)

        // todo connect to jira projects of current project

        let els = document.querySelectorAll('li')
        els.forEach(el => el.classList.remove('active-menu'))

        // here set active-menu
        if (e.target.tagName === 'LI') {
            setSelected(e.target.id)
            e.target.classList.add('active-menu')
        } else if (e.target.tagName === 'A') {
            setSelected(e.target.parentNode.id)
            e.target.parentNode.classList.add('active-menu')
        } else {
            setSelected(e.target.parentNode.parentNode.id)
            e.target.parentNode.parentNode.classList.add('active-menu')
        }

        history.push(path)
    }

    if (!auth.user || loading) {
        return <Loader />
    }

    return (
        <>
            <div className="row clear-row justify-between align-center mb-0" style={{ minWidth: '220px' }}>
                <div className="col-auto ml-2">
                    <h5 className="txt-gray" style={{ fontWeight: '600' }}>
                        <i className="fa" style={{
                            // fontSize: '1.5rem',
                            position: 'relative',
                            width: '24px',
                            height: '24px',
                            borderRadius: '5px',
                            backgroundColor: '#845ab9',
                            // color: 'white',
                            // paddingLeft: '7px',
                            // paddingBottom: '10px',
                            marginBottom: '-2px'
                        }} ><span
                            style={{
                                position: 'absolute',
                                fontSize: '1.4rem',
                                left: '6.5px',
                                top: '1px',
                                color: 'white',
                                fontFamily: 'Sans-serif'
                            }}
                        >s</span></i> Selecty
                    </h5>
                </div>

                {/*SETTINGS*/}
                {/* <div className="col-auto mr-2">
                    <i className="fa fa-cogs" aria-hidden="true" style={{ cursor: 'pointer', paddingTop: '12px' }} onClick={(e) => { menuClickHandler(e, `/user/profile`) }} />
                </div> */}
            </div>

            <div className="row clear-row flex-column text-gray" style={{ overflowY: 'auto' }}>
                {/*SEARCH*/}
                <div className="col-auto ml-2 mr-2 pl-0 pr-0">
                    <ul style={{ marginTop: '0', marginBottom: '0' }} >
                        <li className="sb noHover">
                            <div className="input-field ml-0 pl-0 my-0">
                                <i className="fa fa-search prefix" aria-hidden="true" style={{ width: '24px', height: '15px', fontSize: 'inherit', marginTop: '5px' }} />
                                <input
                                    placeholder="Поиск"
                                    type="text"
                                    id="search"
                                    className=""
                                    style={{ marginLeft: '24px', width: 'calc(100% - 2rem)', borderBottom: 'none' }}
                                    name="search"
                                    autoComplete="off"
                                    onKeyPress={(key) => {
                                        if (key.key === 'Enter') {
                                            history.push(`/`)
                                            history.push(`/search/${key.target.value}`)
                                        }
                                    }}
                                />
                            </div>
                        </li>
                    </ul>
                </div>

                {/*PROJECTS*/}
                <div className="col-auto ml-2 mr-2 mt-1">
                    <p style={{ marginBottom: '5px' }} className=" sb-bolder"><i style={{ fontSize: '0.8em', top: '4px' }} className="fa fa-folder" aria-hidden="true" /> Проекты</p>
                    <ul style={{ marginTop: '0' }} className="">
                        {auth.user && auth.user.projects ? auth.user.projects.map(prj => {
                            return (
                                <React.Fragment key={prj.id}>
                                    <li
                                        className="sb sb-bolder"
                                        onClick={(e) => { projectHandler(e, prj.id) }}

                                    >
                                        <i
                                            className="fa fa-chevron-down"
                                            aria-hidden="true"
                                            style={{ fontSize: '0.8em', top: '4px' }}
                                        />
                                        <i
                                            className="fa fa-chevron-right d-none"
                                            aria-hidden="true"
                                            style={{ fontSize: '0.8em', top: '4px' }}
                                        />
                                        {prj.name.length > 16 ? prj.name.slice(0, 13) + '...' : prj.name}
                                    </li>

                                    <li   id={`prj-${prj.id}-gen`} className={`sb prj-${prj.id}`} onClick={(e) => { menuClickHandler(e, `/project/${prj.id}/general`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Общее</Link></li>
                                    <li id={`prj-${prj.id}-int`} className={`sb prj-${prj.id}`} onClick={(e) => { menuClickHandler(e, `/project/${prj.id}/integrations`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Интеграции</Link></li>

                                    <li id={`prj-${prj.id}-dash`} className={`sb prj-${prj.id}`} onClick={(e) => { menuClickHandler(e, `/project/${prj.id}/dashboard`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Аналитика</Link></li>

                                    <li id={`prj-${prj.id}-arch`} className={`sb prj-${prj.id}`} onClick={(e) => { menuClickHandler(e, `/project/${prj.id}/arch`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Архитектура</Link></li>
                                    <li id={`prj-${prj.id}-blk`} className={`sb prj-${prj.id}`} onClick={(e) => { menuClickHandler(e, `/project/${prj.id}/blocks`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Модули</Link></li>

                                    {/* <li id={`prj-${prj.id}-skl`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/skill`)}} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Навыки</Link></li>
                                    <li id={`prj-${prj.id}-tskl`} className={`sb prj-${prj.id}`} onClick={(e) => {menuClickHandler(e, `/project/${prj.id}/teamskill`)}} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Навыки сотрудников</Link></li> */}
                                    <li id={`prj-${prj.id}-rdmp`} className={`sb prj-${prj.id}`} onClick={(e) => { menuClickHandler(e, `/project/${prj.id}/roadmap`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Дорожная карта</Link></li>

                                </React.Fragment>
                            )
                        }) : (<li></li>)}
                    </ul>
                </div>

                {/*ADD PROJECT*/}
                <div className="col-auto ml-2 mr-2">
                    <ul style={{ marginTop: '0', marginBottom: '0' }}>
                        <li   id="" className="" onClick={(e) => { menuClickHandler(e, `/project/create`) }}>
                            <Link to={'#'}><i style={{ fontSize: '0.8em', top: '4px' }} className="fa fa-plus" aria-hidden="true" /> Добавить проект</Link>
                        </li>
                    </ul>
                </div>


                <div className="col-auto ml-2 mr-2">
                    <ul style={{ marginTop: '0', marginBottom: '0' }}>
                        <li id="" className="" onClick={(e) => { menuClickHandler(e, `/allSkills`) }}>
                            <Link to={'#'}><i style={{ fontSize: '0.8em', top: '4px' }}  className="fa fa-tags" aria-hidden="true" /> Навыки</Link>
                        </li>
                    </ul>
                </div>


                <div className="col-auto ml-2 mr-2">
                    <ul style={{ marginTop: '0', marginBottom: '0' }}>
                        <li id="" className="" onClick={(e) => { menuClickHandler(e, `/allStaffSkills`) }}>
                            <Link to={'#'}><i style={{ fontSize: '0.8em', top: '4px' }} className="fa fa-user" aria-hidden="true" /> Навыки сотрудников</Link>
                        </li>
                    </ul>
                </div>

                {/* <li id={`prj-${1}-skl`} className={`sb prj-${1}`} onClick={(e) => { menuClickHandler(e, `/project/${1}/skill`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Навыки</Link></li>
                <li id={`prj-${1}-tskl`} className={`sb prj-${1}`} onClick={(e) => { menuClickHandler(e, `/project/${1}/teamskill`) }} ><Link to={`#`}><i className="fa fa-cogs opacity-0" aria-hidden="true" /> Навыки сотрудников</Link></li> */}

                {/*LOGOUT*/}
                <div className="col-auto ml-2 mr-2" style={{ bottom: '10px' }}>
                    <ul style={{ marginTop: '0', marginBottom: '0' }}>
                        <li className="left sb"><a href="#!" onClick={logoutHandler}><i style={{ fontSize: '0.8em', top: '4px' }} className="fa fa-sign-out" aria-hidden="true" /> Выход</a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}