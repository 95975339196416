import React, {useCallback, useContext, useEffect, useState} from 'react'
import {AuthContext} from "../../context/AuthContext"
import {useHistory, useParams} from "react-router-dom"
import {useMessage} from "../../hooks/message.hook"
import {useHttp} from "../../hooks/http.hook"
import {Loader} from "../partials/Loader"
import {Header} from "../partials/Header"
import {NoTable} from "../partials/NoTable"
import {Icon} from "../partials/Icon"
import {BlockForm} from "./BlockForm"
import {SidebarContext} from "../../context/SidebarContext"


export const BlockPage = () => {
    const id = useParams().id
    const { token, logout } = useContext(AuthContext)
    const sBC = useContext(SidebarContext)
    const history = useHistory()
    const {loading, request, error, clearError} = useHttp()
    const message = useMessage()
    const [project, setProject] = useState(null)
    const [blockId, setBlockId] = useState(-1)

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
        if (document.getElementById('description')) window.M.textareaAutoResize(document.getElementById('description'))
        // // Инициализация modal
        // let el = document.querySelector('.modal')
        // if (el) setMd(window.M.Modal.init(el))
    })

    const getProject = useCallback(async (token, id) => {
        try {
            if (token && id) {
                const data = await request(`/api/project/blocks/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
                setProject(data.project)
            }
        } catch (e) {
            console.log(e)
        }
    }, [request])

    useEffect(() => {
        // prj.toggle(true, id)
        getProject(token, id)

    }, [getProject, id, token])

    useEffect(() => {
        // console.log('project', project)
    }, [project])

    // if we come from Arch "+ add block"
    useEffect(() => {
        if (project && !loading && sBC.menuId === 'block') {
            showModal()
        }
    }, [sBC, project, loading])

    const updateOnChange = () => {
        getProject(token, id)

        // close modal
        let el = document.querySelector('.modal')
        let fade = document.querySelector('.modal-overlay')
        if (fade) fade.parentNode.removeChild(fade)
        if (el) window.M.Modal.init(el).close()

        if (project && !loading && sBC.menuId === 'block') {
            history.push(`/project/${project.id}/arch`)
            sBC.toggle(false, project.id)
            sBC.toggleMenu('arch')

        }
    }

    const showModal = (id = -new Date()) => {
        // Инициализация modal
        let el = document.querySelector('.modal')
        if (el) {
            setBlockId(id)
            window.M.Modal.init(el).open()
            setTimeout(() => {
                window.M.updateTextFields()
                if (document.getElementById('description')) window.M.textareaAutoResize(document.getElementById('description'))
            }, 200)
        }
    }

    const createBlock = () => {
        console.log('create block')
        showModal()
    }

    const deleteHandler = async id => {
        console.log(`delete block ${id}`)
        try {
            const data = await request(`/api/block/${id}`, 'DELETE', null, {authorization: 'Bearer ' + token})
            message(data.message)
            updateOnChange()
        } catch (e) {
            console.log(e)
        }
    }

    if (!project || loading) {
        return <Loader />
    }

    return (
        <>
            <Header params={{
                title: `Модули`,
                subTitle: 'Изменение модулей',
                bk: [
                    {
                        title: project ? project.name : '',
                        // actionHandler: () => {history.goBack()}
                        actionHandler: () => {}
                    },
                    {
                        title: `Модули`,
                        actionHandler: () => {}
                    },
                ],
                btnL: {
                    actionHandler: ()=>{console.log('left')},
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: createBlock,
                    title: 'Создать модуль',
                    display: 'block'
                },
                loading
            }}/>

           <div className="row clear-row afterHeader">
                <div className="col s12 mt-2">

                    {project && project.blocks && project.blocks.length ? (
                        <div className="col s12 pl-0">
                            <table className="highlight clearTable">
                                <thead>
                                <tr>
                                    {/* <th className="txt-gray center" />
                                    <th className="txt-gray">Тип</th> */}
                                    <th className="txt-gray">Название</th>
                                    <th className="txt-gray">Описание</th>
                                    <th className="txt-gray" />
                                </tr>
                                </thead>
                                <tbody>
                                {project && project.blocks && project.blocks.length ? project.blocks.map(c => {
                                    return(
                                        <tr
                                            key={c.id}
                                        >
                                            {/* <td className="py-0" style={{width: '22px'}}>{c.type === 'Action' ? <Icon name='flash' size='20px' mt='7px'/> : <Icon name='box'  size='20px' mt='7px'/>}</td>
                                            <td className="py-0">{c.type}</td> */}
                                            <td className="py-0">{c.name || '-'}</td>
                                            <td className="py-0">{c.description}</td>
                                            <td className="py-0">
                                                <i
                                                    style={{cursor: 'pointer'}}
                                                    className="fa fa-pencil-square-o opacity-0"
                                                    aria-hidden="true"
                                                    // onClick={() => {history.push(`/project/${c.projectId}/testCases/${c.id}`)}}
                                                    onClick={() => {showModal(c.id)}}
                                                />
                                                <i
                                                    style={{cursor: 'pointer'}}
                                                    className="fa fa-trash-o pl-1 opacity-0"
                                                    aria-hidden="true"
                                                    onClick={() => {deleteHandler(c.id)}}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }) : (<></>)}
                                </tbody>
                            </table>
                        </div>
                    ) : (<NoTable params={{ title: `В проекте нет модулей!` }} />)}

                </div>
            </div>

            {blockId && project ? <BlockForm options={{blockId, project}} updateOnChange={updateOnChange}/> : <></>}
        </>
    )
}