import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { SidebarContext } from "../../context/SidebarContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { NoTable } from "../partials/NoTable"
import { Skill } from "../partials/Skill"
import { Tag } from "../partials/Tag"


export const TeamSkillPage = ({ showHeader = true, setUserId = null }) => {
    const id = useParams().id
    const { token, logout } = useContext(AuthContext)
    const sBC = useContext(SidebarContext)
    const history = useHistory()
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const [project, setProject] = useState(null)
    const [users, setUsers] = useState([]) // All Users
    const [jiraUsers, setJiraUsers] = useState([]) // Jira Users
    const [connected, setConnected] = useState(false)
    const [allSkills, setAllSkills] = useState([])
    const [rerender, setRerender] = useState(0)
    const [selectedRow, setSelectedRow] = useState(null)

    // const [searching, setSearching] = useState(false)
    const [filtered, setFiltered] = useState([]) // All Users

    const [form, setForm] = useState({
        projectId: Number(id),
        userId: -1,
        jiraUser: '',
        name: '',
    })

    const [skillForm, setSkillForm] = useState({
        teamId: 0,
        level: 0,
        skillId: 0,
    })








    console.log('users------=---', users)

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
        // if (document.getElementById('description')) window.M.textareaAutoResize(document.getElementById('description'))
        // // Инициализация modal
        // let el = document.querySelector('.modal')
        // if (el) setMd(window.M.Modal.init(el))
    })

    const getProject = useCallback(async (token, id) => {
        try {

            const data = await request(`/api/team/users`, 'GET', null, { authorization: 'Bearer ' + token })
            // const data = await request(`/api/project/team/${id}`, 'GET', null, { authorization: 'Bearer ' + token })

            console.log('data----!!!!!!!', data.users)
            setProject(data.project)
            setUsers(data.users)
            setFiltered(data.users)

            // console.log('project', data.project) // here skills (array of project skills) & teams (array of users with skills & level)
            // console.log('users', data.users) // All users in program

            // data.project && data.project.teams && data.project.teams.length ? setFiltered(data.project.teams) : setFiltered([])

            // // Get jira users
            // if (data.project.jiraPass) {
            //     const sk = await request(`/api/jira/allUsers`, 'POST', {
            //         jiraName: data.project.jiraName,
            //         jiraUrl: data.project.jiraUrl,
            //         jiraPass: data.project.jiraPass,
            //     }, { authorization: 'Bearer ' + token })
            //     if (sk.connected) {
            //         setConnected(true)
            //         setJiraUsers(sk.users)
            //         // console.log('Statuses', sk.statuses) // All statuses

            //     }
            // } else {
            //     setConnected(false)
            //     setJiraUsers([])
            // }

        } catch (e) {
            console.log(e)
        }
        // }, [request])
    }, [rerender])



    console.log("users---", users)

    useEffect(() => {
        (async () => {


            const result = await request(`/api/skill/all`, 'GET', null, { authorization: 'Bearer ' + token })
            // console.log('allSkills', result)
            setAllSkills(result.skills)


        })()
    }, [rerender])


    // console.log('allSkills', allSkills)


    useEffect(() => {
        // prj.toggle(true, id)
        getProject(token, id)

    }, [getProject, id, token])

    useEffect(() => {
        // console.log('project', project)
        // console.log('form', form)
        console.log('skillForm', skillForm)
        // console.log('jiraUsers', jiraUsers)
    }, [project, form, jiraUsers, skillForm])

    // Show modal for matching user to JiraUser
    const showUserModal = (id = -new Date()) => {
        if (users.length && jiraUsers.length) {
            setForm({ ...form, userId: users[0].id, jiraUser: jiraUsers[0].emailAddress })
        }

        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))

        // Инициализация modal
        let el = document.querySelector('#userModal')
        if (el) {
            // setBlockId(id)
            window.M.Modal.init(el).open()
            setTimeout(() => {
                window.M.updateTextFields()
            }, 200)
        }
    }

    // Show modal for match skill to user
    const showModal = (id = -new Date()) => {
        console.log('TeqmMate', id)
        // Set form from skill
        setSkillForm({ teamId: id, skillId: project && project.skills && project.skills.length ? project.skills[0].id : 0, level: 0 })

        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))

        // Инициализация modal
        let el = document.querySelector('#skillModal')
        if (el) {
            // setBlockId(id)
            window.M.Modal.init(el).open()
            setTimeout(() => {
                window.M.updateTextFields()
            }, 200)
        }
    }

    const changeUserHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const changeSkillHandler = event => {
        setSkillForm({ ...skillForm, [event.target.name]: Number(event.target.value) })
    }

    console.log('skillForm--', skillForm)

    const validate = () => {
        let ok = true

        // if (form.userId <= 0 || !form.userId) {
        //     document.getElementById('userId').classList.add('invalid')
        //     ok = false
        // }

        // if (!form.jiraUser) {
        //     document.getElementById('jiraUser').classList.add('invalid')
        //     ok = false
        // }


        return ok
    }

    const validateSkill = () => {
        let ok = true

        if (skillForm.level < 0 || skillForm.level > 10) {
            document.getElementById('level').classList.add('invalid')
            ok = false
        }

        if (!skillForm.skillId) {
            document.getElementById('skillId').classList.add('invalid')
            ok = false
        }


        return ok
    }

    // UPDATE Matching Users to JiraUsers
    const updateHandler = async () => {
        // if (!validate()) return

        console.log('form to save----', form)
        try {
            //create teams match
            const data = await request('/api/team/create', 'POST', { ...form }, { authorization: 'Bearer ' + token })
            message(data.message)

        } catch (e) {
            console.log(e)
            setForm({ ...form, userId: '', jiraUser: '', name: '' })
        }

        setForm({ ...form, userId: '', jiraUser: '', name: '' })

        // close user modal
        let el = document.querySelector('#userModal')
        let fade = document.querySelector('.modal-overlay')
        if (fade) fade.parentNode.removeChild(fade)
        if (el) window.M.Modal.init(el).close()

        getProject(token, id)

    }

    const updateSkillHandler = async () => {
        if (!validateSkill()) return

        console.log('skillform to save', skillForm)
        try {
            //create skill for user
            console.log("12")
            const data = await request('/api/team/addSkill', 'POST', { ...skillForm }, { authorization: 'Bearer ' + token })
            message(data.message)

        } catch (e) {
            console.log(e)
            setSkillForm({ ...skillForm, teamId: 0, skillId: 0, level: 0 })
        }

        setSkillForm({ ...skillForm, teamId: 0, skillId: 0, level: 0 })

        // close user modal
        let el = document.querySelector('#skillModal')
        let fade = document.querySelector('.modal-overlay')
        if (fade) fade.parentNode.removeChild(fade)
        if (el) window.M.Modal.init(el).close()

        getProject(token, id)

    }

    // Delete user from team
    const deleteUserHandler = async id => {
        console.log(`delete user ${id}`)
        try {
            const data = await request(`/api/team/${id}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)
            setRerender(rerender + 1)
            getProject(token, project.id)

        } catch (e) {
            console.log(e)
        }
    }

    // Delete skill from user
    const deleteSkillHandler = async id => {
        console.log(`delete skill ${id}`)
        try {
            const data = await request(`/api/team/skill/${id}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)
            setRerender(rerender + 1)
            getProject(token, project.id)


        } catch (e) {
            console.log(e)
        }
    }


    // if (!project || loading) {
    //     return <Loader />
    // }

    return (
        <>
            {
                showHeader && <Header params={{
                    title: `Навыки`,
                    subTitle: 'Управление навыками сотрудников',
                    bk: [
                        {
                            title: project ? project.name : '',
                            // actionHandler: () => {history.goBack()}
                            actionHandler: () => { }
                        },
                        {
                            title: `Навыки сотрудников`,
                            actionHandler: () => { }
                        },
                    ],
                    btnL: {
                        actionHandler: () => { },
                        title: 'Уровень влияет на ожидаемый est по задаче этим человеком',
                        display: 'none'
                    },
                    btnR: {
                        // actionHandler: () => {showUserModal()},
                        actionHandler: () => { showUserModal() },
                        title: 'Добавить сотрудника',
                        display: 'block'
                    },
                    loading
                }} />
            }


            <div className={`row clear-row ${showHeader ? 'afterHeader' : ''}  justify-start`}>
                <div className={`col s6  ml-0 ${showHeader ? 'mt-2' : 'mt-2'}`}>
                    <div id="search" className={`${showHeader ? 'pt-1' : ''}`}>
                        <div className="input-field ml-0 pl-0 my-0 text-gray">
                            <i className="fa fa-search prefix" aria-hidden="true" style={{ width: '24px', height: '15px', fontSize: 'inherit', marginTop: '5px' }} />
                            <input
                                placeholder="Поиск"
                                type="text"
                                id="blck-search"
                                className=""
                                style={{ marginLeft: '24px', width: 'calc(100% - 30px)', borderBottom: 'none' }}
                                name="search"
                                autoComplete="off"
                                onKeyUp={(key) => {
                                    // setSearching(true)
                                    key.target.value
                                        ? setFiltered(users.filter(el => el.userName.toLowerCase().includes(key.target.value.toLowerCase())
                                            ||
                                            el.jiraUser.toLowerCase().includes(key.target.value.toLowerCase())
                                            ||
                                            el.teamUserSkills.some((skillObj) =>
                                                skillObj.skill.name.toLowerCase().includes(key.target.value.toLowerCase())
                                            )

                                        ))
                                        : setFiltered(users)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row clear-row">
                <div className="col s12">
                    {project ?
                        <div style={{ backgroundColor: '#fff4cd', color: '#866607', padding: '10px', textAlign: 'justify', marginTop: '50px' }}>
                            <span style={{ fontWeight: 600 }}>Важно!</span> У вас не настроена интеграция с Jira,
                            поэтому функция прогнозирование ресурсов и связка тегов пока недоступны.
                            Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
                                sBC.toggleMenu('integration')
                                sBC.toggle(false, project.id)
                                history.push(`/project/${project.id}/integrations`)
                            }} >здесь.</Link>
                        </div> :
                        <>
                            <div className="col s12 pl-0">
                                <table className="highlight clearTable">
                                    <thead>
                                        <tr>
                                            <th />
                                            <th className="txt-gray">Имя</th>
                                            <th className="txt-gray">Электронная почта</th>
                                            <th className="txt-gray" style={{ paddingLeft: '5px' }}>Навыки</th>
                                            <th className="txt-gray"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filtered ? filtered.map(c => {
                                            return (
                                                <tr
                                                    onClick={() => {

                                                        if (!showHeader) {
                                                            setSelectedRow((prev) => prev !== null ? null : c.id)
                                                            setUserId((prev) => prev !== null ? null : c.id)
                                                        }




                                                    }
                                                    }
                                                    style={selectedRow === c.id ? { backgroundColor: 'rgba(66, 134, 222, 0.25)' } : {}}
                                                    key={c.id}
                                                >
                                                    <td className="center" style={{ maxWidth: '20px' }}>
                                                        <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: '#d4c05b', margin: 'auto' }} />
                                                    </td>
                                                    <td className="py-0">{c.userName || '-'}</td>
                                                    <td className="py-0">{c.jiraUser || '-'}</td>
                                                    <td className="py-0 pl-0">{
                                                        c.teamUserSkills && c.teamUserSkills.length ? c.teamUserSkills.map(s => {
                                                            return (
                                                                <Skill key={s.id} skillId={s.id} name={s.skill.name} level={s.level} onDelete={deleteSkillHandler} />
                                                            )
                                                        }) : <></>
                                                    } <Tag uid={c.id} name='+ добавить навык' style='gray' css={{ color: 'black', cursor: 'pointer' }} onClick={showModal} /></td>
                                                    <td className="py-0">
                                                        <i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-trash-o pl-1 opacity-0 mr-2"
                                                            aria-hidden="true"
                                                            onClick={async () => {
                                                                await deleteUserHandler(c.id)
                                                            }}
                                                        />
                                                    </td>

                                                </tr>
                                            )
                                        }) : (
                                            <tr style={{ borderBottom: 'none' }} className="nohover">
                                                <td colSpan="4">
                                                    <NoTable params={{ title: `There are no Users in this team` }} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </>}
                </div>
            </div>


            {/*Modal User*/}
            <div id="userModal" className="modal w-600" style={{ overflowY: 'auto', overflowX: 'hidden' }}>

                <div className="modal-content">
                    <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee' }}>
                        <div className="col">
                            <h5 style={{ fontWeight: '600', marginTop: 0 }}>Новый сотрудник</h5>
                        </div>
                        <div className="col">
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ paddingRight: 0, marginTop: '-5px' }}><i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" /></button>
                        </div>
                    </div>

                    <div className="row clear-row flex-row justify-start align-center mb-0 mt-1">
                        <div className="col s6 ml-0">
                            {/*Name*/}
                            {/* <div className="input-field mb0-underlined mr-2 mb-0">

                                <select
                                    id="userId"
                                    name="userId"
                                    className="validate"
                                    value={form.userId}
                                    onChange={changeUserHandler}
                                >
                                    {project && users.length ? users.map((p, idx) => <option key={idx} value={p.id}>{p.email}</option>) : (<option value="0">-</option>)}
                                </select>
                                <label htmlFor="userId">Имя</label>
                            </div> */}


                            <div className="input-field mr-2 mb-0">
                                <input
                                    type="text"
                                    id="name"
                                    // className="validate"
                                    name="name"
                                    style={{ marginBottom: 0 }}
                                    value={form.name}
                                    onChange={changeUserHandler}
                                />
                                <label htmlFor="name">Имя сотрудника</label>
                            </div>





                        </div>
                        <div className="col s6 ml-0">
                            <div className="input-field mr-2 mb-0">
                                <input
                                    type="text"
                                    id="jiraUser"
                                    // className="validate"
                                    name="jiraUser"
                                    style={{ marginBottom: 0 }}
                                    value={form.jiraUser}
                                    onChange={changeUserHandler}
                                />
                                <label htmlFor="jiraUser">Почта сотрудника</label>
                            </div>
                            {/*jiraSkills*/}
                            {/* <div className="input-field mb0-underlined mb-0">
                                <select
                                    id="jiraUser"
                                    name="jiraUser"
                                    className="validate"
                                    value={form.jiraUser}
                                    onChange={changeUserHandler}
                                > */}
                            {/*{ project && jiraUsers.length ? jiraUsers.map((p, idx) => <option key={idx} value={p.emailAddress}>{p.emailAddress} [{p.displayName}]</option>) : (<option value="0">-</option>)}*/}
                            {/* {project && jiraUsers.length ? jiraUsers.map((p, idx) => <option key={idx} value={p.emailAddress}>{p.emailAddress}</option>) : (<option value="0">-</option>)}
                                </select>
                                <label htmlFor="jiraUser">Название в Jira *</label>
                            </div> */}
                        </div>
                    </div>

                </div>

                {/*FOOTER SAVE/CANCEL*/}
                <div className="modal-footer overflowHidden" style={{ height: 'auto' }}>
                    <div className="row clear-row justify-end my-1">
                        <div className="col-auto mr-1">
                            {/*Cancel*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            // onClick={() => {setForm({...form, name: '', jiraName: '', skillId: -1})}}
                            >
                                Отмена
                            </button>
                        </div>

                        <div className="col-auto mr-2">
                            {/*Save changes*/}
                            <button
                                className="waves-effect waves-light btn blue darken-1 noUpper"
                                onClick={updateHandler}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/*Modal Skills*/}
            <div id="skillModal" className="modal w-600" style={{ overflowY: 'auto', overflowX: 'hidden' }}>

                <div className="modal-content">
                    <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee' }}>
                        <div className="col">
                            <h5 style={{ fontWeight: '600', marginTop: 0 }}>Добавьте навык к пользователю</h5>
                        </div>
                        <div className="col">
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ paddingRight: 0, marginTop: '-5px' }}><i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" /></button>
                        </div>
                    </div>

                    <div className="row clear-row flex-row justify-start align-center mb-0 mt-1">
                        <div className="col s6 ml-0">
                            {/*Skills*/}
                            <div className="input-field mb0-underlined mr-2 mb-0">
                                <select
                                    id="skillId"
                                    name="skillId"
                                    className="validate"
                                    value={skillForm.skillId}
                                    onChange={changeSkillHandler}

                                >
                                    (<option value="0">-</option>)
                                    {allSkills && allSkills.length && allSkills.map((p, idx) => <option key={idx} value={p.id}>{p.name}</option>)
                                    }




                                </select>
                                <label htmlFor="skillId">Навык *</label>
                            </div>
                        </div>
                        <div className="col s4 ml-0">
                            {/*level*/}
                            <div className="input-field mb0-underlined mb-0">
                                <input
                                    type="number"
                                    id="level"
                                    className="validate"
                                    min='0'
                                    max='10'
                                    name="level"
                                    style={{ marginBottom: 0 }}
                                    value={skillForm.level}
                                    onChange={changeSkillHandler}
                                />
                                <label htmlFor="level">Уровень *</label>
                            </div>
                        </div>
                        <div className="col s2 ml-0">
                            {/*jiraSkills*/}
                            <div className="input-field mb0-underlined mb-0 edit-dis">
                                <input
                                    type="text"
                                    id="10"
                                    className="validate"
                                    name="10"
                                    style={{ marginBottom: 0 }}
                                    value='/10'
                                    disabled
                                />
                            </div>
                        </div>
                    </div>

                </div>

                {/*FOOTER SAVE/CANCEL*/}
                <div className="modal-footer overflowHidden" style={{ height: 'auto' }}>
                    <div className="row clear-row justify-end my-1">
                        <div className="col-auto mr-1">
                            {/*Cancel*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            // onClick={() => {setForm({...form, name: '', jiraName: '', skillId: -1})}}
                            >
                                Отмена
                            </button>
                        </div>

                        <div className="col-auto mr-2">
                            {/*Save changes*/}
                            <button
                                className="waves-effect waves-light btn blue darken-1 noUpper"
                                onClick={updateSkillHandler}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}