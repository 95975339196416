import React, { useCallback, useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { TeamSkillPage } from "../Skill/TeamSkillPage"

export const TeamModal = ({ blockId, onChanged, setRenewTeam }) => {

  const { token, user, logout, login } = useContext(AuthContext)
  const { loading, request, error, clearError, fileUpload } = useHttp()
  const message = useMessage()
  const [userId, setUserId] = useState(null)
  // const [teamates, setTeamates] = useState([])

  useEffect(() => {
    message(error)
    clearError()
  }, [error, message, clearError, logout])





  const updateHandler = useCallback(async () => {
    if (!blockId || !userId) {
      message(`Can't add user`)
      return
    }

    try {
      const data = await request(`/api/roadmap/addUser`, 'POST', { epicId: blockId, teamId: userId }, { authorization: 'Bearer ' + token })
      message(data.message)
      setRenewTeam(blockId)
    } catch (e) {
      console.log(e)
    }
  }, [request, blockId, userId])



  return (
    <div id="modal3" className="modal w-1200" style={{ overflowY: 'auto', overflowX: 'hidden' }}>

      <div className="modal-content">
        <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee' }}>
          <div className="col">
            <h5 style={{ fontWeight: '1200', marginTop: 0 }}>Добавить сотрудника в команду</h5>
          </div>
          <div className="col">
            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ paddingRight: 0, marginTop: '-5px' }}><i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" /></button>
          </div>
        </div>




        <TeamSkillPage showHeader={false} setUserId={setUserId} />



      </div>

      {/*FOOTER SAVE/CANCEL*/}
      <div className="modal-footer overflowHidden" style={{ height: 'auto' }}>
        <div className="row clear-row justify-end my-1">
          <div className="col-auto mr-1">
            {/*Cancel*/}
            <button
              style={{ border: '1px solid grey', color: 'black' }}
              className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
            // onClick={() => { setForm({ ...form, name: '', description: '', epicSummary: '' }) }}
            >
              Отмена
            </button>
          </div>

          <div className="col-auto mr-2">
            {/*Save changes*/}
            <button
              className="waves-effect waves-light btn blue darken-1 noUpper"
              onClick={updateHandler}
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}