import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link, useHistory, useParams } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { SidebarContext } from "../../context/SidebarContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Header } from "../partials/Header"
import { NoTable } from "../partials/NoTable"


export const SkillPage = () => {
    const id = useParams().id
    const { token, logout } = useContext(AuthContext)
    const sBC = useContext(SidebarContext)
    const history = useHistory()
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const [project, setProject] = useState(null)
    const [jiraSkills, setJiraSkills] = useState([])
    const [connected, setConnected] = useState(false)
    const [form, setForm] = useState({
        projectId: id,
        skillId: -1,
        name: '',
        description: '',
        jiraName: ''
    })

    // console.log('id', id)

    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout, history])

    // Активация input для materialize
    useEffect(() => {
        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
        // if (document.getElementById('description')) window.M.textareaAutoResize(document.getElementById('description'))
        // // Инициализация modal
        // let el = document.querySelector('.modal')
        // if (el) setMd(window.M.Modal.init(el))
    })

    const getProject = useCallback(async (token, id) => {
        console.log('1')
        try {

            console.log('2')
            // const data = await request(`/api/project/skills/${id}`, 'GET', null, {authorization: 'Bearer ' + token})
            const data = await request(`/api/skill/all`, 'GET', null, { authorization: 'Bearer ' + token })
            setProject(data.skills)

            console.log('skills', data.skills)

            // Get jira labels aka Skills
            // if (data.project.jiraPass) {
            //     const sk = await request(`/api/jira/allskills`, 'POST', {
            //         jiraName: data.project.jiraName,
            //         jiraUrl: data.project.jiraUrl,
            //         jiraPass: data.project.jiraPass,
            //         prjName: data.project.jiraProject
            //     }, {authorization: 'Bearer ' + token})
            //     if (sk.connected) {
            //         setConnected(true)
            //         setJiraSkills(sk.labels.sort())
            //     }
            // } else {
            //     setConnected(false)
            //     setJiraSkills([])
            // }


        } catch (e) {
            console.log(e)
        }
        // }, [request])
    }, [])


    useEffect(() => {
        // prj.toggle(true, id)
        getProject(token, id)

    }, [getProject, id, token])

    useEffect(() => {
        // console.log('project', project)
        console.log('form222', form)
    }, [project, form])


    console.log("project----", project)

    const showModal = (id = -new Date()) => {
        if (id < 0) {
            setForm({ ...form, name: '',  jiraName: jiraSkills[0], skillId: -1 })
        } else {
            let skill = project.find(el => el.id === id)
            // Set form from skill
            setForm({ ...form, name: skill.name, jiraName: skill.jiraName, skillId: skill.id })
        }

        window.M.updateTextFields()
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))

        // Инициализация modal
        let el = document.querySelector('.modal')
        if (el) {
            // setBlockId(id)
            window.M.Modal.init(el).open()
            setTimeout(() => {
                window.M.updateTextFields()
            }, 200)
        }
    }

    const changeSkillHandler = event => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const validate = () => {
        let ok = true

        if (!form.name) {
            document.getElementById('name').classList.add('invalid')
            ok = false
        }

        // if (!form.jiraName) {
        //     document.getElementById('jiraName').classList.add('invalid')
        //     ok = false
        // }


        return ok
    }

    const updateHandler = async () => {
        if (!validate()) return

        console.log('form to save', form)
        try {
            // Here save or update skill
            if (form.skillId < 0) {
                //create skill match
                const data = await request('/api/skill/create', 'POST', { ...form }, { authorization: 'Bearer ' + token })
                message(data.message)
            } else {
                //update skill match
                const data = await request(`/api/skill/${form.skillId}`, 'PUT', { ...form }, { authorization: 'Bearer ' + token })
                message(data.message)
            }
        } catch (e) {
            console.log(e)
            setForm({ ...form, name: '', description: '', jiraName: '', skillId: -1 })
        }

        setForm({ ...form, name: '', description: '', jiraName: '', skillId: -1 })

        // close modal
        let el = document.querySelector('.modal')
        let fade = document.querySelector('.modal-overlay')
        if (fade) fade.parentNode.removeChild(fade)
        if (el) window.M.Modal.init(el).close()

        getProject(token, id)

    }

    const deleteHandler = async id => {
        console.log(`delete skill ${id}`)
        try {
            const data = await request(`/api/skill/${id}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)

            getProject(token, project.id)

        } catch (e) {
            console.log(e)
        }
    }


    // if (!project || loading) {
    //     return <Loader />
    // }

    return (
        <>
            <Header params={{
                title: `Навыки`,
                subTitle: 'Управление навыками',
                bk: [
                    {
                        title: project ? project.name : '',
                        // actionHandler: () => {history.goBack()}
                        actionHandler: () => { }
                    },
                    {
                        title: `Навыки`,
                        actionHandler: () => { }
                    },
                ],
                btnL: {
                    actionHandler: () => { },
                    title: 'btnLeft',
                    display: 'none'
                },
                btnR: {
                    actionHandler: () => { showModal() },
                    title: 'Добавить новый навык',
                    display: 'block'
                },
                loading
            }} />

            <div className="row clear-row afterHeader">
                {/* {project?.skills[0].name} */}
                <div className="col s12 mt-2">

                    {!project ?
                        <div style={{ backgroundColor: '#fff4cd', color: '#866607', padding: '10px', textAlign: 'justify', marginTop: '50px' }}>
                            <span style={{ fontWeight: 600 }}>Важно!</span> У вас не настроена интеграция с Jira,
                            поэтому функция прогнозирование ресурсов и связка тегов пока недоступны.
                            Вы можете настроить интеграцию <Link to={`#`} onClick={() => {
                                sBC.toggleMenu('integration')
                                sBC.toggle(false, project.id)
                                history.push(`/project/${project.id}/integrations`)
                            }} >здесь.</Link>
                        </div> :
                        <>
                            <div className="col s12 pl-0">
                                <table className="highlight clearTable">
                                    <thead>
                                        <tr>
                                            <th className="txt-gray">Название навыка</th>
                                            <th className="txt-gray">Описание навыка</th>
                                            <th className="txt-gray">Действие</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {project ? project.map(c => {
                                            return (
                                                <tr
                                                    key={c.id}
                                                >
                                                    <td className="py-0">{c.name}</td>
                                                    <td className="py-0">{c.jiraName || '-'}</td>
                                                    <td className="py-0">
                                                        <i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-pencil-square-o opacity-0"
                                                            aria-hidden="true"
                                                            // onClick={() => {history.push(`/project/${c.projectId}/testCases/${c.id}`)}}
                                                            onClick={() => {
                                                                showModal(c.id)
                                                            }}
                                                        />
                                                        <i
                                                            style={{ cursor: 'pointer' }}
                                                            className="fa fa-trash-o pl-1 opacity-0"
                                                            aria-hidden="true"
                                                            onClick={async () => {
                                                                await deleteHandler(c.id)
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        }) : (
                                            <tr style={{ borderBottom: 'none' }} className="nohover">
                                                <td colSpan="3">
                                                    <NoTable params={{ title: `Нет навыков` }} />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>

                                </table>
                            </div>
                        </>}
                </div>
            </div>

            {/*Modal*/}

            <div id="modal1" className="modal w-600" style={{ overflowY: 'auto', overflowX: 'hidden', minHeight: '240px' }}>

                <div className="modal-content">
                    <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee' }}>
                        <div className="col">
                            <h5 style={{ fontWeight: '600', marginTop: 0 }}>Навыки</h5>
                        </div>
                        <div className="col">
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ paddingRight: 0, marginTop: '-5px' }}><i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" /></button>
                        </div>
                    </div>

                    <div className="row clear-row flex-row justify-start align-center mb-0">
                        <div className="col s6 ml-0">
                            {/*Name*/}
                            <div className="input-field mr-2 mb-0">
                                <input
                                    type="text"
                                    id="name"
                                    // className="validate"
                                    name="name"
                                    style={{ marginBottom: 0 }}
                                    value={form.name}
                                    onChange={changeSkillHandler}
                                />
                                <label htmlFor="name">Название навыка *</label>
                            </div>
                        </div>
                        <div className="col s6 ml-0">
                            {/*jiraSkills*/}
                            <div className="input-field mb0-underlined mb-0">
                            <input
                                    type="text"
                                    id="description"
                                    // className="validate"
                                    name="description"
                                    style={{ marginBottom: 0 }}
                                    value={form.description}
                                    onChange={changeSkillHandler}
                                />
                                <label htmlFor="jiraName">Описание навыка</label>
                            </div>
                        </div>
                    </div>

                </div>

                {/*FOOTER SAVE/CANCEL*/}
                <div className="modal-footer overflowHidden" style={{ height: 'auto' }}>
                    <div className="row clear-row justify-end my-1">
                        <div className="col-auto mr-1">
                            {/*Cancel*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            // onClick={() => {setForm({...form, name: '', jiraName: '', skillId: -1})}}
                            >
                                Отмена
                            </button>
                        </div>

                        <div className="col-auto mr-2">
                            {/*Save changes*/}
                            <button
                                className="waves-effect waves-light btn blue darken-1 noUpper"
                                onClick={updateHandler}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}