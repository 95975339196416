import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
// import {useHistory} from "react-router-dom"
import { useMessage } from "../../hooks/message.hook"
// import {Loader} from "../partials/Loader"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

export const BlockForm = ({ options, updateOnChange }) => {
    const { token, user, logout, login } = useContext(AuthContext)
    const { loading, request, error, clearError, fileUpload } = useHttp()


    const [selectedStartDate, setSelectedStartDate] = useState(null)
    const [selectedEndDate, setSelectedEndDate] = useState(null)

    const [skills, setSkills] = useState([])
    const [periods, setPeriods] = useState([])


    const [allSkills, setAllSkills] = useState([])

    const message = useMessage()
    // const history = useHistory()
    const [props, setProps] = useState([])
    const [block, setBlock] = useState({
        id: - new Date(),
        projectId: options.project.id,
        name: '',
        type: 'Block',
        description: ''
    })

    let newBlock = {
        id: - new Date(),
        projectId: options.project.id,
        name: '',
        type: 'Block',
        status: 'Планирование',
        description: ''
    }

    let newProp = {
        id: - new Date(),
        blockId: options.blockId,
        skill: 0,
        level: 0,
        amount: 0,
        // selectedId: 0,
        options: []
    }



    let newPeriod = {
        id: - new Date(),
        blockId: options.blockId,
        startDate: new Date(),
        endDate: new Date(),
    }

    // console.log('options in modal', options)
    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout])

    useEffect(() => {
        if (options.blockId < 0) {
            setProps([newProp])
            setSkills([newProp])
            setPeriods([newPeriod])
            setBlock({ ...newBlock })
        } else {
            setBlock({ ...options.project.blocks.find(b => b.id === options.blockId) })
            // setProps([...options.project.blocks.find(b => b.id === options.blockId).properties])
            // setSkills([...options.project.blocks.find(b => b.id === options.blockId).moduleSkill])
            // setPeriods([...options.project.blocks.find(b => b.id === options.blockId).modulePeriod])
        }
    }, [options])

    useEffect(() => {
        // console.log('props', props)
        // console.log('block', block)
        if (document.querySelectorAll('select')) window.M.FormSelect.init(document.querySelectorAll('select'))
        // if (document.querySelectorAll('.react-datepicker-wrapper')) window.M.FormSelect.init(document.querySelectorAll('.react-datepicker-wrapper'))
    }, [props, skills, periods])

    const addPropertyHandle = () => {
        if (validate()) {
            setSkills(prev => { return [...prev, newProp] })
            let el = document.querySelector('#modal1')
            if (el) {
                el.scrollTop = el.scrollHeight// - el.clientHeight
            }
        }
    }


    // console.log('options', options)

    useEffect(() => {
        (async () => {
            // get block skils / get block periods / get all skills

            const allSkills = await request(`/api/skill/all`, 'GET', null, { authorization: 'Bearer ' + token })
            setAllSkills(allSkills.skills.map(s => { return { name: s.name, value: s.name } }))

            if (block.id > 0) {
                const blockSkills = await request(`/api/skillAndPeriod/skills/${block.id}`, 'GET', null, { authorization: 'Bearer ' + token })
                const blockPeriods = await request(`/api/skillAndPeriod/periods/${block.id}`, 'GET', null, { authorization: 'Bearer ' + token })
                setSkills(blockSkills.skills)
                setPeriods(blockPeriods.periods)


                console.log('blockSkills', blockSkills)
                console.log('blockPeriods', blockPeriods)
            }

        })()
    }, [block])




    const addPeriodHandler = () => {
        if (validate()) {
            setPeriods(prev => { return [...prev, newPeriod] })
            let el = document.querySelector('#modal1')
            if (el) {
                el.scrollTop = el.scrollHeight// - el.clientHeight
            }
        }
    }




    // const changePropHandler = (event, id) => {
    //     let idx = props.findIndex(el => el.id === id)
    //     props[idx][event.target.name] = event.target.value
    //     setProps([...props])
    // }

    const deletePropHandler = (id) => {
        setSkills([...skills.filter(p => p.id !== id)])
    }

    const changeBlockHandler = event => {
        setBlock({ ...block, [event.target.name]: event.target.value })
    }

    const updateOpts = (propId, data) => {
        let idx = props.findIndex(el => el.id === propId)
        props[idx]['options'] = data
        setProps([...props])
    }

    const validate = () => {
        let ok = true

        // if (!block.name) {
        //     document.getElementById('name').classList.add('invalid')
        //     ok = false
        // }

        // props.forEach(p => {
        //     if (!p.name) {
        //         document.getElementById(`Pname-${p.id}`).classList.add('invalid')
        //         ok = false
        //     }
        // })

        return ok
    }

    const updateHandler = async () => {
        if (!validate()) return

        if (block.id < 0) {
            // create
            try {
                const data = await request('/api/block/create', 'POST', { block, props }, { authorization: 'Bearer ' + token })

                // console.log('data', data)
                let blockId = data.blockId

                // foreach file on options save
                // if (props.length) {
                //     for (let i = 0; i < props.length; i++) {
                //         let propId = data.props.find(el => el.start === props[i].id).id
                //         console.log('propId', propId)

                //         if (props[i].options.length) {
                //             for (let j = 0; j < props[i].options.length; j++) {
                //                 let optId = data.opts.find(el => el.start === props[i].options[j].id).id
                //                 console.log('optId', optId)

                //                 if (props[i].options[j].path instanceof File) {
                //                     // save file
                //                     const fd = new FormData()
                //                     fd.append('snippet', props[i].options[j].path)

                //                     const fl = await fileUpload('/api/block/upload', 'POST', fd, { authorization: 'Bearer ' + token, blockId, propId, optId })
                //                 }
                //             }
                //         }
                //     }
                // }


                if (skills.length) {

                    for (const skill of skills) {

                        if (skill.id > 0) {

                            // update skill
                            const result = await request(`/api/skillAndPeriod/skills/${skill.id}`, 'PUT', { ...skill }, { authorization: 'Bearer ' + token })
                            console.log('result', result)
                        } else {
                            // create skill
                            skill.blockId = blockId
                            delete skill.id
                            const result = await request('/api/skillAndPeriod/skills/create', 'POST', { ...skill }, { authorization: 'Bearer ' + token })
                            skill.id = result.id

                        }

                    }
                }


                if (periods.length) {
                    for (const period of periods) {

                        if (period.id > 0) {
                            // update skill
                            await request(`/api/skillAndPeriod/periods/${period.id}`, 'PUT', { ...period }, { authorization: 'Bearer ' + token })
                        } else {
                            // create skill
                            period.blockId = blockId
                            delete period.id
                            const result = await request('/api/skillAndPeriod/periods/create', 'POST', { ...period }, { authorization: 'Bearer ' + token })
                            period.id = result.id
                        }

                    }
                }


                message(data.message)

            } catch (e) {
                console.log(e)
            }

        } else {
            // upadte
            try {
                // update block
                const data = await request(`/api/block/${block.id}`, 'PUT', { block, props }, { authorization: 'Bearer ' + token })

                // check new files and save, and delete old (if changed or removed)
                let blockId = data.blockId

                // foreach file on options save
                // if (props.length) {
                //     for (let i = 0; i < props.length; i++) {
                //         let propId = data.props.find(el => el.start === props[i].id).id
                //         console.log('propId', propId)

                //         if (props[i].options.length) {
                //             for (let j = 0; j < props[i].options.length; j++) {
                //                 let optId = data.opts.find(el => el.start === props[i].options[j].id).id
                //                 console.log('optId', optId)

                //                 if (props[i].options[j].path instanceof File) {
                //                     // save file
                //                     const fd = new FormData()
                //                     fd.append('snippet', props[i].options[j].path)

                //                     const fl = await fileUpload('/api/block/upload', 'POST', fd, { authorization: 'Bearer ' + token, blockId, propId, optId })
                //                 }
                //             }
                //         }
                //     }
                // }


                if (skills.length) {

                    for (const skill of skills) {

                        if (skill.id > 0) {

                            // update skill
                            const result = await request(`/api/skillAndPeriod/skills/${skill.id}`, 'PUT', { ...skill }, { authorization: 'Bearer ' + token })
                            console.log('result', result)
                        } else {
                            // create skill
                            skill.blockId = blockId
                            delete skill.id
                            const result = await request('/api/skillAndPeriod/skills/create', 'POST', { ...skill }, { authorization: 'Bearer ' + token })
                            skill.id = result.id

                        }

                    }
                }


                if (periods.length) {
                    for (const period of periods) {

                        if (period.id > 0) {
                            // update skill
                            await request(`/api/skillAndPeriod/periods/${period.id}`, 'PUT', { ...period }, { authorization: 'Bearer ' + token })
                        } else {
                            // create skill
                            period.blockId = blockId
                            delete period.id
                            const result = await request('/api/skillAndPeriod/periods/create', 'POST', { ...period }, { authorization: 'Bearer ' + token })
                            period.id = result.id
                        }

                    }
                }


                message(data.message)
            } catch (e) {
                console.log(e)
            }
        }

        updateOnChange()
    }




    const changeSkillHandler = (e, id) => {
        console.log(e.target.name, e.target.value)
        let idx = skills.findIndex(el => el.id === id)
        skills[idx][e.target.name] = e.target.value
        setSkills([...skills])

    }



    const periodsHandler = (e, id, type) => {

        // setSkills()
        console.log(e.toISOString())

        let idx = periods.findIndex(el => el.id === id)
        periods[idx][type] = e
        setPeriods([...periods])


    }



    return (
        <>
            <div id="modal1" className="modal w-600" style={{ overflowY: 'auto', overflowX: 'hidden' }}>

                <div className="modal-content">
                    <div className="row clear-row flex-row justify-between mb-0" style={{ borderBottom: '1px solid #eee' }}>
                        <div className="col">
                            <h5 style={{ fontWeight: '600', marginTop: 0 }}>Создание/Редактирование модуля</h5>
                        </div>
                        <div className="col" >
                            {/*<button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{paddingRight: 0, marginTop: '-15px'}}><i className="fa fa-times" aria-hidden="true" /></button>*/}
                            <button className="modal-close waves-effect waves-green btn-flat txt-gray" style={{ paddingRight: 0, marginTop: '-5px' }}><i style={{ color: '#eaeaea' }} className="fa fa-times" aria-hidden="true" /></button>
                        </div>
                    </div>
                    {/*<p className="txt-gray mt-0 mb-1">Создание/Редактирование блока</p>*/}

                    {/*BLOCK*/}
                    <div className="row clear-row flex-row justify-between mb-0 mt-1">
                        <div className="col s12">
                            {/*Name*/}
                            <div className="input-field mr-2 mb-0">
                                <input
                                    type="text"
                                    id="name"
                                    // className="validate"
                                    name="name"
                                    style={{ marginBottom: 0 }}
                                    value={block.name}
                                    onChange={changeBlockHandler}
                                />
                                <label htmlFor="name">Название модуля*</label>
                            </div>

                            {/*Description*/}
                            <div className="input-field mr-2">
                                <textarea
                                    id="description"
                                    name="description"
                                    value={block.description}
                                    onChange={changeBlockHandler}
                                    className="materialize-textarea" />
                                <label htmlFor="description">Описание</label>
                            </div>
                        </div>

                        {/* <div className="col s6"> */}
                        {/*Type*/}
                        {/* <div className="input-field">
                                <select
                                    id="type"
                                    name="type"
                                    value={block.type}
                                    onChange={changeBlockHandler}
                                > */}
                        {/*{ projects.length ? projects.map(p => <option key={p.id} value={p.key}>{p.name}</option>) : (<option value="0">-</option>)}*/}
                        {/* <option value="Block">Блок</option>
                                    <option value="Action">Действие</option>
                                </select>
                                <label htmlFor="type">Тип*</label>
                            </div>
                        </div> */}
                    </div>


                    <div className="row clear-row flex-row justify-start mb-1">
                        <div className="">
                            <p className="txt-gray" style={{ fontSize: '1.2rem', textDecoration: 'underline', marginLeft: "11px" }}>Характеристики</p>
                        </div>
                    </div>

                    {/*PROPERTIES*/}
                    {/*todo:  iterable make component*/}
                    {skills && skills.length ? skills.map(p => {
                        return (

                            <React.Fragment key={p.id}>
                                <div className="row clear-row flex-row justify-start align-center mb-0">
                                    <div className="col s4 ml-0" style={{ marginTop: 22 }}>
                                        {/*Name*/}

                                        <select

                                            id={`Ptype-${p.id}`}
                                            name="skill"
                                            value={p.skill}
                                            onChange={(e) => { changeSkillHandler(e, p.id) }}
                                        >
                                            <option value="0">-</option>
                                            {allSkills.length && allSkills.map(p => <option key={p.value} value={p.value}>{p.name}</option>)}
                                            {/* <option value="Text">Навык</option> */}
                                        </select>
                                        {/*<label htmlFor={`Pname-${p.id}`}>Prop name</label>*/}

                                    </div>

                                    <div className="col s4 ml-0">
                                        <label htmlFor="level">Уровень *</label>
                                        <input
                                            type="number"
                                            id={`level-${p.id}`}
                                            // className="validate"
                                            min='0'
                                            max='10'
                                            name="level"
                                            style={{ marginBottom: 32 }}
                                            label="level"
                                            value={p.level}
                                            onChange={e => { changeSkillHandler(e, p.id) }}
                                        />

                                    </div>

                                    <div className="col s4 ml-0">
                                        <label htmlFor="amount">Количество дней</label>
                                        <input
                                            type="number"
                                            id={`amount-${p.id}`}
                                            // className="validate"
                                            min='1'
                                            // max='10'
                                            name="amount"
                                            style={{ marginBottom: 32 }}
                                            value={p.amount}
                                            onChange={e => { changeSkillHandler(e, p.id) }}
                                        />

                                    </div>
                                    {/* 

                                    <div className="input-field mb0-underlined mb-0">
                                     
                                    </div> */}




                                    {/*LOAD SNIPPET*/} {/* Move to opts component */}
                                    {/*<div className="col s3 ml-0" style={{display: p.type === 'Text' ? 'none' : 'block'}}>*/}
                                    {/*<span*/}
                                    {/*style={{cursor: 'pointer', color: '#0066b9', fontSize: '0.8rem'}}*/}
                                    {/*className=""*/}
                                    {/*aria-hidden="true"*/}
                                    {/*// onClick={() => {deleteHandler(c.id)}}*/}
                                    {/*>*/}
                                    {/*load code snippet*/}
                                    {/*</span>*/}
                                    {/*<input*/}
                                    {/*type='file'*/}
                                    {/*id="snippet"*/}
                                    {/*// ref={inputFile}*/}
                                    {/*style={{display: 'none'}}*/}
                                    {/*// value={form.img}*/}
                                    {/*// onChange={imgChangeHandler}*/}
                                    {/*// accept=".jpg"*/}
                                    {/*/>*/}
                                    {/*</div>*/}

                                    {/*TRASH*/}
                                    <div className="col s1 ml-0 pl-0">
                                        <i
                                            style={{ cursor: 'pointer' }}
                                            className="fa fa-trash-o txt-gray"
                                            aria-hidden="true"
                                            onClick={() => { deletePropHandler(p.id) }}
                                        />
                                    </div>
                                </div>



                                {/*OPTIONS*/}
                                {/* <OptsForm p={p} updateData={updateOpts} /> */}


                            </React.Fragment>
                        )
                    }
                    ) : <></>}





                    <div className="row clear-row flex-row justify-start mb-0 mt-1">
                        <div className="col s4 ml-0">
                            {/*ADD PROPERTY BTN*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                className="waves-effect waves-dark btn white lighten-1 text-lower"
                                onClick={addPropertyHandle}
                            >
                                + Добавить
                            </button>
                        </div>
                    </div>


                    <div className="row clear-row flex-row justify-start mt-1">
                        <div className="">
                            <p className="txt-gray" style={{ fontSize: '1.2rem', textDecoration: 'underline', marginLeft: "11px" }}>Периоды</p>
                        </div>
                    </div>




                    {periods && periods.length ? periods.map((p) => (
                        <React.Fragment key={p.id}>
                            <div className="row datepicker-row" style={{ marginTop: '1rem' }}>
                                <div className="col s6">
                                    <DatePicker
                                        selected={p.startDate}
                                        dateFormat="dd.MM.yy"
                                        id={`start-${p.id}`}
                                        onChange={(e) => periodsHandler(e, p.id, "startDate")}
                                        placeholder="Дата начала"
                                    />
                                </div>
                                <div className="col s6">
                                    <DatePicker
                                        selected={p.endDate}
                                        dateFormat="dd.MM.yy"
                                        id={`end-${p.id}`}
                                        onChange={(e) => periodsHandler(e, p.id, "endDate")}
                                        placeholder="Дата окончания"
                                    />
                                </div>
                            </div>
                        </React.Fragment>
                    )) : null}


                    <div className="row clear-row flex-row justify-start mb-0 mt-1">
                        <div className="col s4 ml-0">
                            {/*ADD PROPERTY BTN*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                className="waves-effect waves-dark btn white lighten-1 text-lower"
                                onClick={addPeriodHandler}
                            >
                                + Добавить
                            </button>
                        </div>
                    </div>




                </div>

                {/*FOOTER SAVE/CANCEL*/}
                <div className="modal-footer overflowHidden mb-1">
                    <div className="d-flex justify-end">
                        <div className="col-auto mr-1">
                            {/*Cancel*/}
                            <button
                                style={{ border: '1px solid grey', color: 'black' }}
                                className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                            // onClick={connectHandler}
                            >
                                Отмена
                            </button>
                        </div>

                        <div className="col-auto mr-1">
                            {/*Save changes*/}
                            <button
                                className="waves-effect waves-light btn blue darken-1 noUpper"
                                onClick={updateHandler}
                            >
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}