import { differenceInDays, format } from "date-fns"
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Link } from "react-router-dom"
import { AuthContext } from "../../context/AuthContext"
import { useHttp } from "../../hooks/http.hook"
import { useMessage } from "../../hooks/message.hook"
import { Skill } from "../partials/Skill"
import { SkillNoDelete } from "../partials/SkillNoDelete"
import { Tag } from "../partials/Tag"

export const Block = ({
    epicId = +new Date(),
    epic = {}, // module -block
    project = {}, // client
    css = {},
    showBlockModal = () => { },
    showTeamModal = () => { },
    updateOnChange = () => { },
    setRenewTeam = () => { },
    renewTeam = null,
    users = [],
    onDelete = (eid) => { console.log('EpicId', eid) },
    onAddHr = () => { }
}) => {


    console.log('project----', project)
    const [teamates, setTeamates] = useState([])
    const [tags, setTags] = useState([])
    const [teamStatus, setTeamStatus] = useState(true)
    const { loading, request, error, clearError } = useHttp()
    const message = useMessage()
    const { token, logout } = useContext(AuthContext)
    const [usr, setUsr] = useState({
        epicId: epic.id,
        teamId: project.teams.length ? project.teams[0].id : 0
    })
    const [k, setK] = useState(1)

    // show request errors
    useEffect(() => {
        message(error)
        clearError()
    }, [error, message, clearError, logout])

    const [skilledUsers, setSkilledUsers] = useState([])
    const [autoTeam, setAutoTeam] = useState([])

    const getTeam = async () => {
        const data = await request(`/api/roadmap/getTeam/${epic.id}`, 'GET', null, { authorization: 'Bearer ' + token })






        if (data.team?.length) {
            setTeamates(data.team)
        }
    }


    useEffect(() => {
        console.log('teamates RENEW', teamates)
    }, [teamates])


    useEffect(() => {
        (async () => {
            try {

                const data = await request(`/api/roadmap/getUserAndSkill`, 'GET', null, { authorization: 'Bearer ' + token })
                await getTeam()
                setRenewTeam(null)
                // console.log('SAVING ARCH')
            } catch (e) {
                console.log(e)
            }
        })()

    }, [])


    useEffect(() => {
        (async () => {
            if (renewTeam && renewTeam === epic.id) {
                await getTeam()
            }
        })()

    }, [renewTeam])


    const recountEstimate = (epicTags) => {
        // coefficients for recount estimate by skill level
        const k = [2, 1.8, 1.7, 1.5, 1.3, 1.2, 1, 0.8, 0.6, 0.5]

        let teamUsers = []
        epicTags = epicTags.map(t => t.name)

        // teamUsers with Skills for this Epic
        if (project.teams.length) {
            teamUsers = project.teams.filter(el => el.epicTeams.filter(e => String(e.epicId) === epic.id).length)
        }

        // console.log('teamUsers', teamUsers)
        // console.log('Tags', epicTags)

        // find max skills and user for each epicTag
        let maxSkills = epicTags.map(t => {
            // get matched user with skill
            let usrWithSkill = teamUsers
                .filter(tu => tu.teamUserSkills.filter(us => us.skill.name === t).length)
                .map(tu => {
                    return {
                        user: tu.user,
                        skill: tu.teamUserSkills
                            .filter(s => s.skill.name === t)
                            .map(s => {
                                return { name: s.skill.name, level: s.level }
                            }).length ? tu.teamUserSkills.filter(s => s.skill.name === t).map(s => { return { name: s.skill.name, level: s.level } })[0] : null
                    }
                })

            // console.group(`Tag: ${t}`)
            // console.log('Users with skill', usrWithSkill)
            // console.groupEnd()

            // find max skill level
            let max = { skill: { level: 7 } }
            if (usrWithSkill.length) {
                max = usrWithSkill.reduce((prev, curr) => {
                    return prev.skill.level > curr.skill.level ? prev : curr
                })
            }

            return {
                tag: t,
                user: max
            }
        })

        // console.log('Max skills', maxSkills)

        // get middle level for all skills
        let avg = 7
        if (maxSkills.length) {
            avg = Math.floor(maxSkills.reduce((sum, val) => sum + val.user.skill.level, 0) / maxSkills.length)
        }

        // console.log('avg', avg)

        // set coefficient
        setK(k[avg - 1])

        // console.log('k', k[avg-1])

        // save k
        request(`/api/roadmap/addK`, 'POST', { epicId: epic.id, coefficient: k[avg - 1] }, { authorization: 'Bearer ' + token })

    }

    // collect all Epic tags, make it unique, check is it linked to project skills (jira <-> SDMT) and set it to state: tags
    // useEffect(() => {
    //     let allTags = []
    //     epic.tasks.forEach(t => {
    //         allTags = [...allTags, ...t.labels]
    //     })

    //     // console.log('allTags', allTags)
    //     allTags = [...new Set(allTags)]

    //     // here match project skills to tags
    //     // and check with team skills
    //     allTags = allTags.map(t => {
    //         return project.skills.find(s => s.jiraName === t) ? project.skills.find(s => s.jiraName === t) : { id: 0, name: `!!${t}!!`, color: 'red' }
    //     })

    //     // setTags([...new Set(allTags)])
    //     setTags(allTags)

    //     // console.log(allTags.map(t => t.name).findIndex(el => el === 'DB'))
    //     // RECOUNT ESTIMATE?
    //     recountEstimate(allTags, null)
    // }, [epic, project])

    const changeUsrHandler = event => {
        setUsr({ ...usr, [event.target.name]: event.target.value })
    }

    const deleteEpicHandler = useCallback(async (eId) => {
        try {
            // const sk = await request(`/api/jira/deleteEpic`, 'POST', {
            //     jiraName: project.jiraName,
            //     jiraUrl: project.jiraUrl,
            //     jiraPass: project.jiraPass,
            //     epicId: eId
            // }, { authorization: 'Bearer ' + token })


            const data = await request(`/api/block/${eId}`, 'DELETE', null, { authorization: 'Bearer ' + token })



            onDelete(eId)
        } catch (e) {
            console.log(e)
        }
    }, [request])

    const deleteTeammate = useCallback(async (tid) => {
        try {
            const data = await request(`/api/roadmap/deleteUser/${tid}`, 'DELETE', null, { authorization: 'Bearer ' + token })
            message(data.message)
            await getTeam()
        } catch (e) {
            console.log(e)
        }
        onDelete(null)
    }, [request])

    const addTeammate = useCallback(async () => {
        if (!project.teams.length || !usr.teamId) {
            message(`Can't add user`)
            return
        }

        try {
            const data = await request(`/api/roadmap/addUser`, 'POST', { ...usr }, { authorization: 'Bearer ' + token })
            message(data.message)
            onDelete(null)
        } catch (e) {
            console.log(e)
        }
    }, [request, usr])



    const [matchingUsers, setMatchingUsers] = useState([])
    useEffect(() => {
        const requiredSkills = new Set(tags.map(tag => tag.name))

        // Шаг 2: Фильтрация пользователей по наличию всех необходимых навыков
        const res = project.teams.filter(user => {
            // Получение навыков пользователя
            const userSkills = new Set(user.teamUserSkills.map(skillObj => skillObj.skill.name))
            // Проверка, содержит ли пользователь все необходимые навыки
            return [...requiredSkills].every(skill => userSkills.has(skill))
        })

        setMatchingUsers(res)
    }, [tags, project])



    // Эффект для установки skilledUsers
    useEffect(() => {
        const skilledEmails = project.teams
            .filter(el => el.epicTeams.some(e => String(e.epicId) === epic.id))
            .map(el => el.user.email)
        setSkilledUsers(skilledEmails)
    }, [project.teams, epic.id])



    const isTeamFull = () => {
        if (
            project.teams.filter(el => el.epicTeams.filter(e => String(e.epicId) === epic.id).length).length + 1
            <
            tags.length) {
            return true
        }
        return false
    }



    // const readyTeam = project.teams.reduce((acc, team) => {
    //     team.teamUserSkills.forEach(userSkill => {
    //         const skillName = userSkill.skill.name
    //         const skillLevel = userSkill.level

    //         // Проверяем, был ли уже добавлен навык с таким же именем
    //         const existingSkill = acc.find(item => item.skill === skillName)

    //         if (tags.some(tag => tag.name === skillName) && !skilledUsers.includes(team.user.email) && !existingSkill
    //         && isTeamFull()
    //             // &&
    //             // project.teams.filter(el => el.epicTeams.filter(e => String(e.epicId) === epic.id).length).length
    //             // <
    //             // project.teams.filter(el => el.epicTeams.filter(e => String(e.epicId) === epic.id).length)
    //         ) {
    //             acc.push({
    //                 email: team.user.email,
    //                 skill: skillName,
    //                 level: skillLevel,
    //                 teamId: team.id
    //             })

    //         }
    //     })
    //     return acc
    // }, [])


    const readyTeam1 = (() => {
        // Карта для хранения сотрудников и их навыков
        const userSkillsMap = new Map()

        // Заполняем карту навыков для каждого сотрудника в проекте
        project.teams.forEach(team => {
            const email = team.user.email
            if (!userSkillsMap.has(email)) {
                userSkillsMap.set(email, {
                    email,
                    skills: [],
                    teamId: team.id
                })
            }

            team.teamUserSkills.forEach(userSkill => {
                userSkillsMap.get(email).skills.push({
                    skill: userSkill.skill.name,
                    level: userSkill.level
                })
            })
        })

        // Получаем список необходимых навыков
        const requiredSkills = tags.map(tag => tag.name)

        // Функция для сортировки сотрудников по уровню навыков
        const sortBySkillLevel = (a, b) => {
            const sumLevelA = a.skills.reduce((sum, skill) => sum + skill.level, 0)
            const sumLevelB = b.skills.reduce((sum, skill) => sum + skill.level, 0)
            return sumLevelB - sumLevelA // сортировка по убыванию уровня навыков
        }

        // Сортируем сотрудников по суммарному уровню необходимых навыков
        const sortedUsers = Array.from(userSkillsMap.values()).sort(sortBySkillLevel)

        // Находим минимальное количество сотрудников для покрытия всех навыков
        const finalTeam = []
        const coveredSkills = new Set()
        for (const user of sortedUsers) {
            const userSkills = user.skills.filter(skill => requiredSkills.includes(skill.skill))
            let added = false
            for (const skill of userSkills) {
                if (!coveredSkills.has(skill.skill)) {
                    if (!added && isTeamFull()) {
                        finalTeam.push({
                            email: user.email,
                            skill: skill.skill,
                            level: skill.level,
                            teamId: user.teamId
                        })
                        added = true
                    }
                    coveredSkills.add(skill.skill)
                }
            }
            if (coveredSkills.size === requiredSkills.length) break
        }

        // Возвращаем итоговую команду
        return finalTeam
    })









    const readyTeam = (() => {
        const finalTeam = []

        const blockTags = epic?.module_skills?.map(skill => ({
            skill: skill.skill,
            level: skill.level,
            amount: skill.amount
        }))
        console.log('----blockTags', blockTags, epic?.name)


        for (const tag of blockTags) {
            let requiredAmount = tag.amount


            // Фильтруем сотрудников, подходящих по текущему навыку и уровню
            const suitableEmployees = users.filter(employee =>
                employee.teamUserSkills.some(skill =>
                    skill.skill.name.toLowerCase() === tag.skill.toLowerCase() &&
                    skill.level >= tag.level
                )
            )


            // Сортируем подходящих сотрудников по уровню навыка (убывание)
            suitableEmployees.sort((a, b) => {
                const skillLevelA = a.teamUserSkills.find(skill => skill.skill.name.toLowerCase() === tag.skill.toLowerCase()).level
                const skillLevelB = b.teamUserSkills.find(skill => skill.skill.name.toLowerCase() === tag.skill.toLowerCase()).level
                return skillLevelB - skillLevelA
            })


            // Добавляем сотрудников, пока не наберется необходимое количество
            for (const employee of suitableEmployees) {
                if (requiredAmount <= 0) break

                // Проверяем, добавлен ли сотрудник уже в команду
                if (!finalTeam.includes(employee)) {
                    finalTeam.push(employee)
                    requiredAmount--
                }
            }
        }

        return finalTeam
    })



    useEffect(() => {
        if (!teamates?.length) {
            setAutoTeam(readyTeam())
            console.log('readyTeam()----', readyTeam())
        } else {
            setAutoTeam([])
        }
    }, [teamates])



    // < td align = "left" className = 'pl-0 txt-gray' > {
    //     tags.length ? tags.map((t, idx) => {
    //         return (
    //             <Tag key={idx} name={t.name} style={
    //                 project.teams.length && project.teams.filter(el => el.epicTeams.filter(e => String(e.epicId) === epic.id).length).filter(te => te.teamUserSkills.length && te.teamUserSkills.filter(ts => ts.skill.name === t.name).length).length ? 'green' : 'red'
    //             } ml='0' mr='10px' />
    //         )
    //     }) : `Нет связанных навыков`
    // }</ >





    // console.log(project, epic)
    // [
    //     {
    //         "id": 4,
    //         "projectId": 3,
    //         "name": "backEND",
    //         "jiraName": "10006",
    //         "createdAt": "2024-06-12T08:13:31.000Z",
    //         "updatedAt": "2024-06-12T08:13:31.000Z"
    //     },
    //     {
    //         "id": 3,
    //         "projectId": 3,
    //         "name": "DEVOPS",
    //         "jiraName": "DEVOPS",
    //         "createdAt": "2024-06-12T07:51:03.000Z",
    //         "updatedAt": "2024-06-12T07:51:03.000Z"
    //     }
    // ]


    const truncate = (str, num) => {
        return str.length > num ? str.slice(0, num) + '...' : str
    }


    const applyTeamHandler = async () => {
        // console.log('readyTeam', readyTeam)

        // Проверка, что readyTeam не пустой
        // if (!readyTeam || readyTeam.length === 0) {
        //     // console.warn('readyTeam is empty')
        //     return
        // }

        // Создаем массив промисов для каждого запроса
        const requests = autoTeam.map(member => {
            // Создаем объект user для каждого члена команды
            let user = {
                "epicId": epic.id,
                "teamId": member.id // Используем teamId из текущего member
            }

            // Отправляем запрос и возвращаем промис
            return request(`/api/roadmap/addUser`, 'POST', { ...user }, { authorization: 'Bearer ' + token })
        })

        try {
            // Ожидаем завершения всех запросов
            const results = await Promise.all(requests)
            message('Team applied successfully')
            // Обработка результатов запросов
            // results.forEach(result => {
            //     // message(result.message)
            // })

            // Очистка данных или другие действия после завершения всех запросов
            onDelete(null)
        } catch (e) {
            console.error('Error in applyTeamHandler:', e)
        }
    }


    const setStatusHandler = async (id) => {
        const status = epic.status === 'Планирование' ? 'В работе' : epic.status === 'В работе' ? 'Готово' : epic.status

        try {
            const data = await request(`/api/block/${id}`, 'PUT', { block: { ...epic, status }, props: {} }, { authorization: 'Bearer ' + token })
            console.log('error data', data)
            updateOnChange()
        } catch (e) {

        }
    }



    return (
        <>
            <div className='blockWrapper d-flex justify-between'>
                <div className="row clear-row w-100">

                    {/*EPIC*/}
                    <div className="col s7 epic">
                        <table>
                            <tbody className='clearTable'>
                                {/*Epic title + delete*/}
                                <tr>
                                    <td colSpan='2'>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                                            <h5 className='mt-0 py-0 mb-0'>
                                                {epic.name} <span className='txt-gray'>- {epic.status}</span>




                                            </h5>

                                            <i
                                                style={{ cursor: 'pointer', marginLeft: '15px', marginTop: '8px', fontSize: '20px' }}
                                                className="fa fa-pencil-square-o opacity-0"
                                                aria-hidden="true"
                                                onClick={async () => { showBlockModal(epic.id) }}
                                            />

                                            <i
                                                style={{ cursor: 'pointer', marginTop: '5px', fontSize: '20px', paddingLeft: '5px' }}
                                                className="fa fa-trash-o  opacity-0"
                                                aria-hidden="true"
                                                onClick={async () => { deleteEpicHandler(epic.id) }}
                                            />
                                        </div>
                                    </td>
                                </tr>

                                {/*Epic tasks*/}
                                {/* <tr>
                                    <td style={{ width: '24px', fontSize: '20px', verticalAlign: 'top' }}><i className="fa fa-hdd-o txt-lightGray" aria-hidden="true" /></td>
                                    <td align="left" className='pl-0 txt-gray'>
                                        {epic.tasks.length ?
                                            `${epic.tasks.filter(ts => ts.status.name === 'Готово').length} / ${epic.tasks.length} задач готово (${Math.floor(epic.tasks.filter(ts => ts.status.name === 'Готово').length * 100 / epic.tasks.length)}%)` :
                                            `В Jira нет связанных задач для данного этапа, необходимо добавить задачи.`
                                        }
                                    </td>
                                </tr> */}

                                {/*Project skills linked to Epic labels */}
                                <tr>
                                    <td style={{ width: '24px', fontSize: '20px', verticalAlign: 'top' }}><i className="fa fa-hdd-o txt-lightGray" style={{ fontWeight: 'bold' }} aria-hidden="true" /></td>
                                    <td align="left" className='pl-0 txt-gray'>{epic.module_skills.length ? epic.module_skills.map((t, idx) => {
                                        return (
                                            // <Tag key={idx} name={t.name} style={
                                            //     project.teams.length && project.teams.filter(el => el.epicTeams.filter(e => String(e.epicId) === epic.id).length).filter(te => te.teamUserSkills.length && te.teamUserSkills.filter(ts => ts.skill.name === t.name).length).length ? 'green' : 'red'
                                            // } ml='0' mr='10px' />
                                            <Tag key={idx} name={t.skill} style={'red'} ml='0' mr='10px' />
                                        )
                                    }) : `Нет связанных навыков`}</td>
                                </tr>

                                {/*Epic Esimate*/}
                                <tr>
                                    <td style={{ width: '24px', fontSize: '20px', verticalAlign: 'top' }}><i className="fa fa-clock-o txt-lightGray" aria-hidden="true" /></td>
                                    <td align="left" className='pl-0 txt-gray'>

                                        {epic.module_periods.length > 0 ?


                                            epic?.module_periods?.map(p => (
                                                <React.Fragment key={p.id}>
                                                    <p>С {format(new Date(p.startDate), "dd.MM.yy")} по {format(new Date(p.endDate), "dd.MM.yy")} {new Date() < new Date(p.startDate)
                                                        ?
                                                        'до начала проекта ' + differenceInDays(new Date(p.startDate), new Date()) + ' д.' : new Date() < new Date(p.endDate) ? 'до окончания проекта ' + differenceInDays(new Date(p.endDate), new Date()) + ' д.' : ''}  </p>
                                                </React.Fragment>
                                            ))

                                            :
                                            `Примерная оценка срока появится после заполнения данных о команде`

                                            // <span>
                                            //     Прогнозируемое время выполнения <br />
                                            //     <span style={{ fontSize: '24px' }}>{Math.ceil(epic.aggregateprogress.total / 3600 * k)} часов</span>
                                            //     {epic.aggregateprogress.percent ? <span> (Прогресс: {Math.floor(epic.aggregateprogress.percent / k)}%)</span> : <></>}
                                            // </span> :
                                            // `Примерная оценка срока появится после заполнения данных о команде`
                                        }
                                    </td>
                                </tr>

                                {/*Epic HR SHOW + remove*/}
                                <tr>
                                    <td style={{ width: '24px', fontSize: '20px', verticalAlign: 'top' }}><i className="fa fa-user-circle-o txt-lightGray" aria-hidden="true" /></td>
                                    <td align="left" className='pl-0 txt-gray'>При необходимости вы можете сформировать задачу на найм необходимых кандидатов</td>
                                </tr>

                                {/*Epic HR ADD*/}
                                <tr>
                                    <td style={{ width: '24px', fontSize: '20px', verticalAlign: 'top' }}><i className="fa fa-user-circle-o txt-lightGray opacity-0" aria-hidden="true" /></td>
                                    <td align="left" className='pl-0 txt-gray' style={{ fontWeight: 400, textDecoration: 'underline' }} onClick={() => { onAddHr('Задача', epic.id, epic.summary) }}><Link className='txt-gray' to='#'>Поставить задачу в HR</Link></td>
                                </tr>


                            </tbody>
                        </table>

                        <button className="waves-effect waves-light btn grey lighten-3 noUpper mr-1"
                            style={{ color: 'black', marginLeft: "53px", marginTop: "-3px", fontSize: '13px' }}
                            onClick={() => setStatusHandler(epic.id)}
                        >
                            {epic.status === 'Планирование' ? 'Запустить модуль' : epic.status === 'В работе' ? 'Завершить модуль' : 'Модуль завершен'}
                        </button>


                    </div>

                    {/*TEAM*/}
                    <div className="col s5 team">
                        <p>Команда <i className="fa fa-users txt-lightGray" aria-hidden="true" /></p>

                        {/*Teammates*/}
                        {teamates?.length ? teamates.map(el => {
                            // setSkilledUsers([...skilledUsers, el.user.email])
                            return (
                                <React.Fragment key={el.id}>
                                    <table>
                                        <tbody className='clearTable'>

                                            <tr>
                                                <td className='w-100 pl-0'>
                                                    <span style={{fontSize: '14px'}}>
                                                        {/*{el.name ? el.user.name : el.user.email}*/}
                                                        <span style={{ marginRight: '10px' }}>{el.userName}</span>
                                                        {el.teamUserSkills.length ?


                                                            // el.teamUserSkills.map(s => (
                                                            //     < Skill key={`${s.id}-${el.id}`}
                                                            //         level={s.level}
                                                            //         name={s.skill.name}

                                                            //         // name={el.teamUserSkills.find(us => tags.map(tg => tg.name).includes(us.skill.name)) ? el.teamUserSkills.find(us => tags.map(tg => tg.name).includes(us.skill.name)).skill.name : el.teamUserSkills[0].skill.name}
                                                            //         // level={el.teamUserSkills.find(us => tags.map(tg => tg.name).includes(us.skill.name)) ? el.teamUserSkills.find(us => tags.map(tg => tg.name).includes(us.skill.name)).level : el.teamUserSkills[0].level}
                                                            //         showDel={false}
                                                            //     />

                                                            // ))

                                                            <>
                                                                < Skill
                                                                    level={el.teamUserSkills[0].level}
                                                                    name={el.teamUserSkills[0].skill.name}
                                                                    showDel={false}
                                                                />



                                                                {el.teamUserSkills.length - 1 > 0 && (
                                                                    <div
                                                                        style={{
                                                                            borderRadius: '5px',
                                                                            border: '1px solid #ccc',
                                                                            color: '#ccc',
                                                                            padding: '0 5px',
                                                                            marginLeft: '5px',
                                                                            display: 'inline-block',
                                                                            cursor: 'pointer', // Указатель мыши
                                                                            fontSize: '14px'
                                                                        }}
                                                                        title={el.teamUserSkills.map(skill => skill.skill.name).join(', ')} // Подсказка
                                                                    >
                                                                        + {el.teamUserSkills.length - 1}
                                                                    </div>
                                                                )}




                                                            </>












                                                            : <></>}

                                                    </span>
                                                </td>
                                                <td style={{ width: '20px', fontSize: '18px', verticalAlign: 'middle', paddingRight: 0 }}>
                                                    <i
                                                        style={{ cursor: 'pointer' }}
                                                        className="fa fa-trash-o pl-1 opacity-0 txt-gray"
                                                        aria-hidden="true"
                                                        // onClick={async () => { deleteTeammate(el.epicTeams.find(e => String(e.epicId) === epic.id).id) }}
                                                        onClick={async () => { deleteTeammate(el.epicTeams.find(e => e.epicId === epic.id).id) }}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </React.Fragment>
                            )
                        }) : <span>Нет команды</span>}

                        {/*ADD USER*/}
                        <div className="row clear-row flex-row justify-start align-center mb-0">

                            <button className="waves-effect waves-light btn grey lighten-3 noUpper mr-1"
                                style={{ color: 'black', marginLeft: "11px", marginTop: "5px", fontSize: '13px' }}

                                onClick={() => showTeamModal(epic.id)}
                            >
                                Добавить сотрудника
                            </button>


                        </div>


                        {/* READY TEAM */}
                        {autoTeam.length > 0 ?

                            <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                                <div style={{ borderTop: '1px solid #786C2D', borderLeft: '1px solid #786C2D', borderRight: '1px solid #786C2D', borderRadius: '5px', background: 'rgba(212, 192, 91, 0.1)', padding: '2px', width: 'max-content' }}>
                                    {/* `max-content` для контейнера */}
                                    <span style={{ margin: '0px 5px' }}>Готовая команда</span>
                                </div>
                                <div style={{ border: '1px solid #786C2D', borderRadius: '5px', background: 'rgba(212, 192, 91, 0.1)', padding: '5px', width: '100%' }}>
                                    {autoTeam.map((member, index) => (

                                        <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', border: '1px solid transparent', gap: '8px', width: '90%' }}>

                                            <p style={{ margin: '0px 5px', display: 'flex', alignItems: 'center' }} >
                                                {truncate(member.userName, 18)}

                                            </p>

                                            <div>
                                                <SkillNoDelete key={1} skillId={2} name={member.teamUserSkills[0].skill.name} level={member.teamUserSkills[0].level} />

                                                {member.teamUserSkills.length - 1 > 0 &&
                                                    <div style={{ height: '25px', borderRadius: '5px', border: '1px solid #ccc', color: '#ccc', padding: '0 5px', marginLeft: '5px', display: 'inline-block' }}>
                                                        + {member.teamUserSkills.length - 1}
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    ))}
                                    <div style={{ display: 'flex', justifyContent: 'start', border: '1px solid transparent', gap: '8px', marginTop: '5px', marginBottom: '5px' }}>
                                        {/* <button
                                            style={{ border: '1px solid grey', color: 'black' }}
                                            className="waves-effect waves-dark btn white lighten-1 noUpper modal-close"
                                            onClick={() => { setTeamStatus(false) }}
                                        >
                                            Отклонить команду
                                        </button> */}

                                        <button
                                            className="waves-effect waves-light btn blue darken-1 noUpper"
                                            onClick={applyTeamHandler}
                                            style={{ fontSize: '13px' }}
                                        >
                                            Утвердить команду
                                        </button>
                                    </div>

                                </div>


                            </div>


                            :

                            <></>}


                    </div>
                </div>
            </div >
        </>
    )
}

