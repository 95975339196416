import React from 'react'
import {Loader} from "./Loader"

export const Header = ({params}) => {

    if (!params) {
        return <Loader />
    }

    return (
        <>
        <div className="container-fluid pl-2 pr-2"
             // style={{borderBottom: '1px solid #ccc', position: 'absolute', left: '240px', top: 0, right: 0, height: '65px', backgroundColor: '#fff', zIndex: 200}}
             style={{position: 'fixed', left: '281px', top: 0, right: 0, height: '65px', backgroundColor: '#fff', zIndex: 200}}

        >
            <div className="row clear-row justify-between mt-1 align-center mb-0 w-100" >
                <div className="col-auto">
                    {params.bk && params.bk.length ? params.bk.map((bk, idx) => {return(
                        <React.Fragment key={idx}>
                        <span
                            style={{fontSize: '1.2rem', cursor: 'pointer'}}
                            onClick={bk.actionHandler ? bk.actionHandler : ()=>{}}
                        >{bk.title}</span>{idx >= 0 && params.bk.length > 1 && idx < params.bk.length - 1 ? (<i className="fa fa-chevron-right" aria-hidden="true" style={{textAlign: 'center'}}/>) : (<></>)}
                        </React.Fragment>
                        )}
                    ) : (<p style={{fontSize: '1.2rem'}}>{params ? params.title : ''}</p>)}
                    <p style={{fontSize: '0.9rem'}} className="txt-gray">{params ? params.subTitle : ''}</p>
                </div>
                <div className="d-flex justify-end flex-row">
                    {/* <button
                        className="waves-effect waves-light btn grey lighten-3 noUpper mr-1"
                        style={{color: 'black', display: params.btnL.display, fontSize: '13px'}}
                        onClick={params ? params.btnL.actionHandler : ()=>{}}
                        disabled={params ? params.loading : false}
                    >
                        {params ? params.btnL.title : ''}
                    </button> */}
                    <button
                        className="waves-effect waves-light btn blue darken-1 noUpper"
                        style={{display: params.btnR.display, fontSize: '13px'}}
                        onClick={params ? params.btnR.actionHandler : ()=>{}}
                        disabled={params ? params.loading : false}
                    >
                        {params ? params.btnR.title : ''}
                    </button>
                </div>
            </div>

            {/*<hr style={{display: 'block', height: '1px', border: '0', borderTop: '1px solid #ccc'}}/>*/}
            {/*<br/>*/}
        </div>

        </>
    )
}